<!--
 * @Description: 办事指南
 * @Autor: bjp
 -->
<template>
  <div class="serviceGuide">
    <div class="file">
      <div class="title">档案类</div>
      <ul>
        <li @click="$router.push({path:file.url})" v-for="(file,index) in fileList" :key="file.id">
          <img :src="require('@/assets/views/graduate/file'+(index+1)+'.png')" alt />
          <span>{{file.name}}</span>
        </li>
      </ul>
    </div>
    <div class="hukou">
      <div class="title">户口类</div>
      <ul>
        <li
          @click="$router.push({path:hukou.url})"
          v-for="(hukou,index) in hukouList"
          :key="hukou.id"
        >
          <img :src="require('@/assets/views/graduate/hukou'+(index+1)+'.png')" alt />
          <span>{{hukou.name}}</span>
        </li>
      </ul>
    </div>
    <div class="prove">
      <div class="title">证明开具（免费）
        <span class="tips">如需开具证明,请联系您的专属顾问</span>
      </div>
      <ul>
        <li
          @click="$router.push({path:prove.url})"
          v-for="(prove,index) in proveList"
          :key="prove.id"
        >
          <img :src="require('@/assets/views/graduate/prove'+(index+1)+'.png')" alt />
          <span>{{prove.name}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import "@/sass/views/graduate/serviceGuide.scss";
export default {
  data() {
    return {
      fileList: [
        { id: 1, name: "档案办理", url: "/gallery/danganbanli" },
        { id: 2, name: "档案提取", url: "/gallery/dangantq" },
        { id: 3, name: "档案借阅", url: "/gallery/danganbro?id=1" },
        { id: 4, name: "档案归还", url: "/gallery/danganreturn?id=1" },
        { id: 5, name: "报到手续", url: "/expressmail/expressarchives?type=1" }
      ],
      hukouList: [
        { id: 1, name: "户口办理", url: "/gallery/hukoubanli?id=1" },
        { id: 2, name: "户口迁出", url: "/gallery/residence-emigration?id=1" },
        { id: 3, name: "单页借取", url: "/gallery/cut-from?id=1" },
        { id: 4, name: "单页归还", url: "/gallery/page-return?id=1" },
        { id: 5, name: "单页补办", url: "/gallery/page-add?id=1" },
        { id: 6, name: "报到手续", url: "/expressmail/expressarchives?type=2" }
      ],
      proveList: [
        { id: 1, name: "考研同意报考证明", url: "" },
        { id: 2, name: "考公务员同意报考证明", url: "" },
        { id: 3, name: "政审证明", url: "" },
        { id: 4, name: "工作证明", url: "" },
        { id: 5, name: "代理证明", url: "" },
        { id: 6, name: "存档证明", url: "" },
        { id: 7, name: "解约函", url: "" },
        { id: 8, name: "其他证明", url: "" }
      ]
    };
  }
};
</script>
