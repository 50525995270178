<template>
    <div class="classify-list">
        <div class="navigation">
            <div class="serve" v-for="(item,index) in showdata" :key="index" @click="showType(item.id,index)">
                <span class="classifyname" :class="{'active':index == state}">{{item.name}}</span>
            </div>
        </div>
        <div class="content" v-loading="loading">
            <div class="content-top"><img :src="showdata[state].imgUrl" alt=""></div>
            <div class="contentin" v-if="childlist.find(item=>item.type==curId)">
                <div class="item" v-for="(item,index) in childlist.find(item=>item.type==curId).list" :key="index" @click="goOrder(item)"
                    v-show="item.id!=46">
                    {{item.businessname}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import "@/sass/views/classify/List.scss";
import { mapGetters } from "vuex";
export default {
    name: "Classify",
    data() {
        return {
            showdata: [
                {
                    id: 3,
                    name: "档案",
                    imgUrl: require('@/assets/views/classify/dangan.png')
                },
                {
                    id: 6,
                    name: "应届毕业生",
                    imgUrl: require('@/assets/views/classify/dangan.png')
                },
                {
                    id: 1,
                    name: "社保服务",
                    imgUrl: require('@/assets/views/classify/dangan.png')
                },
                {
                    id: 2,
                    name: "公积金",
                    imgUrl: require('@/assets/views/classify/dangan.png')
                },
                // {
                //   id: 4,
                //   name: "户口"
                // },
                // {
                //   id: 5,
                //   name: "就业"
                // },
                // {
                //   id: 7,
                //   name: "创业"
                // }
            ],
            childlist: [],
            state: 0,
            curId: 3,
            loading: false
        };
    },
    mounted() {
        if (!this.$route.query.id || this.$route.query.id == 0) {
            this.getAllBusiness(3);
        }
    },
    activated() {
        var id = [];
        id.push(this.$route.query.id);
        if (id.indexOf(1) > -1) {
            this.state = 1;
            let state = this.state + 1;
            this.getAllBusiness(state);
        }
        if (id.indexOf(0) > -1) {
            this.state = 0;
            let state = this.state + 1;
            this.getAllBusiness(state);
        }
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo"
        })
    },
    methods: {
        showType(id, index) {
            this.state = index;
            this.curId = id;
            if (!this.childlist.some(item => item.type == id)) {
                this.getAllBusiness(id);
            }
        },
        getAllBusiness(id) {
            console.log(id);
            this.loading = true;
            this.$siaxios.get(`/homepage/allbusiness/${id}`).then(res => {
                this.childlist.push({
                    type: id,
                    list: res
                })
                console.log(this.childlist);
                this.loading = false
            });
        },
        /**
         * 去创建订单  type 0社保1公积金2社保续费3公积金续费
         */
        goOrder(item) {
            if (item.id == 1) {
                this.$router.forward({
                    path: "/creatorder/socialsecurity",
                    query: { type: 0 }
                });
            } else if (item.id == 13) {
                this.$router.forward({
                    path: "/creatorder/accumulationfund",
                    query: { type: 1 }
                });
            } else if (item.id == 46) {
                this.$router.forward("/creatorder/consult");
            } else {
                this.$router.forward({
                    path: "/creatorder/general",
                    query: { id: item.id, businessname: item.businessname }
                });
            }
        }
    }
};
</script>
