<template>
  <div class="stop-pay-index">
    <template v-if="this.$route.query.type==1">
    <div class="stop-pay-title">停保说明：</div>
    <div class="stop-pay-item">
      <div class="stop-lable">1</div>
      <div class="stop-pay-content">
        若您停止购买后，本平台通常会于次月截止时间后的一
        周内操作减员（部分地区可能会有延迟），如果需要提
        前减员的用户，请提前与我们的顾问联系。
      </div>
    </div>
    <div class="stop-pay-item">
      <div class="stop-lable">2</div>
      <div class="stop-pay-content">
        如果您当月申请减员后，又想重新购买当月的社保，请
        务必先联系我们的顾问撤销减员，避免漏缴
      </div>
    </div>
    <div class="stop-pay-item">
      <div class="stop-lable">3</div>
      <div class="stop-pay-content">
        若您提前终止所选套餐，社保未缴费月份可申请退款，
        服务费不再退还，产生的支付通道手续费，需由您自己
        承担。
      </div>
    </div>
    </template>
    <template v-else>
    <div class="stop-pay-title">公积金封存说明：</div>
      
    <div class="stop-pay-item">
      <div class="stop-lable">1</div>
      <div class="stop-pay-content">
        若您停止购买后，本平台通常会于次月截止时间后一周操作封存公积金（部分地区可能会有延迟），需要提前封存的用户，请提前与我们的顾问联系
      </div>
    </div>
    
    <div class="stop-pay-item">
      <div class="stop-lable">2</div>
      <div class="stop-pay-content">
        如果您当月申请封存后，又想重新购买当月的公积金，请务必先联顾问撤销封存，避免漏缴
      </div>
    </div>
    <div class="stop-pay-item">
      <div class="stop-lable">3</div>
      <div class="stop-pay-content">
        若您提前终止所选套餐，公积金未缴费月份可申请退款，服务费不再退还，产生的支付通道手续费，需由您自己承担
      </div>
    </div>
    </template>
    <div class="stop-submit-btn" @click="stopPayment()">提交</div>
  </div>
</template>
<script>
import "@/sass/views/mine/stopPayment.scss";
import { mapGetters } from "vuex";
export default {
  name: "stop-pay-index",
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    if(this.$route.query.type==1) {

    document.title = '申请停保';
    } else {
      
    document.title = '公积金封存';
    }
  },
  methods: {
    /**
     * 停缴
     */
    stopPayment() {
      this.$siaxios
        .get(
          `/mybusiness/saveTingJiao/${this.$route.query.id}/${this.$route.query.type}/${this.$route.query.cityid}`
        )
        .then(() => {
          this.$tips({
            title: "提示",
            describe: "已提交申请",
            callback: () => {
              this.$router.go(-1);
            }
          });
        });
    }
  }
};
</script>
