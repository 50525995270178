<template>
    <div class="eduction">
        <iframe src="https://my.chsi.com.cn/archive/wap/index.jsp" width="100%" height="800" frameborder="0" scrolling="auto" id="iframename"
            name="iframename"></iframe>
    </div>
</template>
<script>
export default {
    data() {
        return {
            html: ''
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
    },
}
</script>
