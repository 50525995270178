<template>
    <div class="advantage-index" >
        <div class="banner-con">
            <div class="banner">
                <span>—&nbsp;&nbsp;缴纳公积金可享优势详解&nbsp;&nbsp;—</span>
            </div>
        </div>
        <div class="cont" >
            <div class="adv-title">
                <div class="adv-img">
                    <img src="@/assets/views/mine/advant.jpg" alt="">
                </div>
                <div class="adv-"> 缴纳公积金 轻享六大优势</div>
                <div class="adv-img">
                    <img src="@/assets/views/mine/advant.jpg" alt="">
                </div>
               
            </div>
            <div class="cont-advantage" >
                <div class="con-introduce">
                    <span>买房优势</span>
                </div>
                <div class="con-advantage">
                    职工购房时可以使用住房公积金贷款，享受较商业贷款低的利率；也可在商业贷款买房时提取公积金作为购房首付款。
                </div>

            </div>
            <div class="advant-line"></div>
            <div class="cont-advantage" >
                <div class="con-introduce">
                    <span>增加职工个人收入</span>
                </div>
                <div class="con-advantage">
                    住房公积金由单位和其在职职工共同缴存，所缴住房公积金全归 职工个人所有，且住房公积金自存入职工账户之日起按规定计息。
                </div>

            </div>
            <div class="advant-line"></div>
            <div class="cont-advantage" >
                <div class="con-introduce">
                    <span>用于儿女购房</span>
                </div>
                <div class="con-advantage">
                    父母缴纳公积金，在儿女购房时可以提取用来偿还本息或者做为 购房首付款。
                </div>

            </div>
            <div class="advant-line"></div>
            <div class="cont-advantage" >
                <div class="con-introduce">
                    <span>税收优惠</span>
                </div>
                <div class="con-advantage">
                    职工缴存的住房公积金，在税务部门规定限额内的部分免征个人 所得税。
                </div>

            </div>
            <div class="advant-line"></div>
            <div class="cont-advantage" >
                <div class="con-introduce">
                    <span>住房消费提取</span>
                </div>
                <div class="con-advantage">
                    职工可以提取住房公积金用于建房、维修、租房、还贷等住房消 费支出。
                </div>

            </div>
            <div class="advant-line"></div>
            <div class="cont-advantage" >
                <div class="con-introduce">
                    <span>非住房消费提取</span>
                </div>
                <div class="con-advantage">
                    职工符合退休、户籍迁出本市、离职、出国、终止劳动关系、重 大疾病（包括家庭成员）、特困家庭等八项情形之一的，可提取 住房公积金。
                </div>

            </div>
        </div>

    </div>
</template>
<script>
    import "@/sass/views/mine/advantage.scss";
    export default {
        name: "index",
        data() {
            return {

            };
        },
    };
</script>
<style lang="scss" scoped>
    .banner-con {
        background: url("../../assets/views/mine/advant-banner_03.jpg") 100% 100%;
        background-size: cover;

    }
</style>