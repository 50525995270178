<template>
  <div class="employment-index">
    <div class="item" @click="Jump1">
      <img src="@/assets/views/classify/entrepreneurship1.png" class="item-icon" alt />
      <div class="e-info">
        <div class="title">一次性创业补贴</div>
        <div class="tips">可享1万元补贴</div>
      </div>
    </div>
    <div class="item" @click="Jump2">
      <img src="@/assets/views/classify/entrepreneurship2.png" class="item-icon" alt />
      <div class="e-info">
        <div class="title">一次性小微企业创业补贴</div>
        <div class="tips">可享2万元补贴</div>
      </div>
    </div>
    <div class="item" @click="Jump3">
      <img src="@/assets/views/classify/entrepreneurship3.png" class="item-icon" alt />
      <div class="e-info">
        <div class="title">一次性创业岗位开发补贴</div>
        <div class="tips">每个岗位2000元的标准</div>
      </div>
    </div>
    <div class="item" @click="Jump4">
      <img src="@/assets/views/classify/entrepreneurship4.png" class="item-icon" alt />
      <div class="e-info">
        <div class="title">创业担保贷款</div>
        <div class="tips">最高可享45万元贷款</div>
      </div>
    </div>
    <div class="item" @click="Jump5">
      <img src="@/assets/views/classify/entrepreneurship5.png" class="item-icon" alt />
      <div class="e-info">
        <div class="title">小微企业创业担保贷款</div>
        <div class="tips">最高可申请300万元 最长2年贷款</div>
      </div>
    </div>
    <div class="item" @click="Jump6">
      <img src="@/assets/views/classify/entrepreneurship6.png" class="item-icon" alt />
      <div class="e-info">
        <div class="title">大学生创业培育“海鸥行动”给予服务机构创业指导补贴</div>
      </div>
    </div>
    <div class="showService" @click="showConsult()">立即咨询</div>
  </div>
</template>

<script>
import "@/sass/views/classify/employment.scss";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods: {
    /**
     * 展示顾问
     */
    showConsult() {
      if (this.userInfo.id == "") {
        this.$router.forward("/login/login");
        return
      }
      this.$consult({ userid: this.userInfo.id })
    },
    Jump1() {
      this.$router.forward({ path: "/jumpdetails/detailk" });
    },
    Jump2() {
      this.$router.forward({ path: "/jumpdetails/detaill" });
    },
    Jump3() {
      this.$router.forward({ path: "/jumpdetails/detailm" });
    },
    Jump4() {
      this.$router.forward({ path: "/jumpdetails/detailn" });
    },
    Jump5() {
      this.$router.forward({ path: "/jumpdetails/detailo" });
    },
    Jump6() {
      this.$router.forward({ path: "/jumpdetails/detailp" });
    }
  }
};
</script>