<template>
  <div class="feedback-index">
    <div class="title">吐槽一下</div>
    <div class="item" @click="param.type=1" :class="{'active':param.type == 1}">
      改善意见
      <img src="@/assets/selected.png" class="selected" alt />
    </div>
    <div class="item" @click="param.type=2" :class="{'active':param.type == 2}">
      业务投诉
      <img src="@/assets/selected.png" class="selected" alt />
    </div>
    <div class="item" @click="param.type=3" :class="{'active':param.type == 3}">
      页面bug
      <img src="@/assets/selected.png" class="selected" alt />
    </div>
    <div class="title">详细描述您遇到的问题</div>
    <div class="inp-con">
      <textarea class="textarea" v-model="param.content" placeholder="为了更好的解决您的问题，请详细描述您的问题"></textarea>
      <div class="uploadimgs">
        <img v-for="item in param.fileImgs" :key="item" :src="item" class="showimgs" alt />
        <div class="addimg" @click="choiseUploadFile" v-if="param.fileids.length<5">
          <input type="file" @change="uploadFile" style="display: none" />
        </div>
      </div>
      <div class="tips">最多可添加5张</div>
    </div>
    <div @click="submit()" class="submit-btn">立即提交</div>
  </div>
</template>

<script>
import "@/sass/views/mine/feedback.scss";
import { mapGetters } from "vuex";
export default {
  name: "Feedback",
  data() {
    return {
      param: {
        appuserid: "", //登录用户的id
        type: 0, //反馈类型1改善意见2业务投诉3功能异常
        content: "", //内容
        fileids: [], //截图id列表
        fileImgs: []
      }
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    this.param.appuserid = this.userInfo.id;
  },
  methods: {
    submit() {
      if (this.param.type == 0) {
        this.$tips({ describe: "请选择吐槽类型" });
        return;
      }
      if (this.param.content == "") {
        this.$tips({ describe: "请描述您的问题" });
        return;
      }
      let _this = this;
      this.$siaxios.post("/opinion/save", this.param, false).then(() => {
        this.$submitSuccess({
          callback() {
            _this.$router.back();
          }
        });
      });
    },
    /**
     * 选中上传图片
     */
    choiseUploadFile(event) {
      let myTaget = event.currentTarget;
      let myfild = myTaget.getElementsByTagName("input");
      myfild[0].click();
    },
    uploadFile(event) {
      let _this = this;
      let myTaget = event.currentTarget;
      let myfild = myTaget.files[0];
      // 压缩
      if (myfild.size <= 1 * 1024 * 1024) {
        //判断图片是否小于1M,是就直接上传
        this.postImg(myTaget, myfild);
      } else {
        // 压缩图片
        let reader = new FileReader();
        reader.readAsDataURL(myfild);
        reader.onloadend = function() {
          let img = new Image();
          img.src = this.result;
          let canvas = document.createElement("canvas");
          let drawer = canvas.getContext("2d");
          img.onload = function() {
            let width = img.width;
            let height = img.height;
            if ((width * height) / 1000000 > 1) {
              width = 720;
              height = width * (img.height / img.width);
            }
            canvas.width = width;
            canvas.height = height;
            drawer.drawImage(img, 0, 0, canvas.width, canvas.height);
            // img.src = canvas.toDataURL("image/jpg", 0.7); //0.7图片质量
            canvas.toBlob(blob => {
              myfild = new File([blob], `${myfild.name.substring(0, 10)}.jpg`);
              _this.postImg(myTaget, myfild);
            });
          };
        };
      }
    },
    postImg(event, myfild) {
      const formData = new FormData();
      formData.append("file", myfild);
      this.$siaxios
        .post("/uploadFile/uploadFile/6", formData, false)
        .then(res => {
          this.param.fileids.push(res.fileid);
          this.param.fileImgs.push(res.fileurl);
        });
        event.value = "";
    }
  }
};
</script>