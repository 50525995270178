<template>
  <div class="security">
    <div class="title">社金管家社保代缴合同</div>
    <section class="rule">
      <h3>提示条款</h3>
      <p class="s1-title">欢迎您与档管家共同签署《档管家社会保险代缴服务协议》（以下简称“本协议”）并使用档管家平台服务！</p>
      <p class="s1-title">各服务条款所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。</p>
      <p class="s1-title">【审慎阅读】您在社会保险服务模块中点击同意本协议之前，应当认真阅读本协议。请您无别审慎阅读、充分理解各条款内容。</p>
      <p
        class="s1-title"
      >【签约动作】当您在本页面下填写信息、阅读并同意本协议后，即表示您已充分阅读、理解并接受本协议的全部内容，并与档管家达成一致。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止使用本程序。</p>
    </section>
    <section class="rule">
      <h3>一、服务内容</h3>
      <p class="s1-title">1、社会保险代缴服务</p>
      <p class="s1-title">系指您与档管家签订网上协议，由档管家按照国家规定为您缴纳养老、医疗、失业和工伤四项社会保险的服务。</p>
      <p class="s1-title">2、社保补缴服务</p>
      <p class="s1-title">系指您与档管家签订网上协议，由档管家按照国家规定的补缴范围为您补缴养老、医疗、失业和工伤四项社会保险的服务。</p>
      <p class="s1-title">3、社保转移服务</p>
      <p class="s1-title">系指您与档管家签订网上协议，由档管家协助您将外地的社会保险转入本地或将本地缴纳的社保转出到外地的服务。</p>
      <p class="s1-title">4、社保基本信息变更服务</p>
      <p class="s1-title">系指您与档管家签订网上协议，由档管家帮助您将之前您在本地社保网上录入的个人信息修改更新为新信息的服务。</p>
      <p class="s1-title">5、退休手续办理服务</p>
      <p class="s1-title">系指您与档管家签订网上协议，在您已经符合国家法律法规要求的法定退休情形的前提下，为您办理退休手续，是您依法享受国家退休人员法定退休待遇的服务。</p>
      <p class="s1-title">6、异地医疗备案服务</p>
      <p
        class="s1-title"
      >系指您与档管家签订网上协议，为实际社保在本地而工作地点长期在外地的您，在本地社保机构进行备案统计，以便您在外地就医也能享受本地相关法定医保待遇的服务。</p>
    </section>
    <section class="rule">
      <h3>二、特别约定</h3>
      <p
        class="s1-title"
      >1、您所选择的所有社会保险服务业务，均是委托与被委托的关系，不存在事实上的劳动关系，故您无需实际上为档管家提供劳动，档管家也无需向您发放工资和提供任何的劳动待遇。</p>
      <p
        class="s1-title"
      >2、档管家为您仅提供代理服务，一切基于劳动关系所产生的费用，包括但不限于补偿金、赔偿金、工伤、医疗等费用以及所产生的相关责任都由您自行承担，与档管家无关。</p>
      <p class="s1-title">3、基于您与档管家的委托与受托关系，档管家不能为您开具包括但不限于在职证明、离职证明、收入证明等有关劳动关系的书面材料。</p>
      <p class="s1-title">4、您在办理相关业务时，应积极配合提供相关证明或资料。因不提供相关证明或不配合办理引发纠纷的，将由您自行承担相关责任。</p>
      <p class="s1-title">5、您在档管家平台选择社会保险服务，所有涉及您社保基数、月缴纳额度等数额都是您自行确认的，档管家将根据您在平台填写的相关数据为您提供代缴服务。</p>
      <p class="s1-title">6、社会保险每年会有相应程度的上浮，上浮标准根据当地人社局所发的具体政策为准，涉及到您在缴纳过程中的差额，档管家将统一进行补收。</p>
      <p
        class="s1-title"
      >7、您有权随时终止代缴社会保险服务，请您在每月13日之前（遇法定节假日提前）将您的终止服务请求在平台提交，如晚于约定日期提交的，将生成当月费用，此费用由您自行承担。</p>
    </section>

    <section class="rule">
      <h3>三、其他</h3>
      <p class="s1-title">本协议未尽事宜，根据我国相关法律、法规以及毕业生分配办事处相关业务规定办理。</p>
      <p
        class="s1-title"
      >本协议条款最终解释权归青岛中海智库人力资源服务有限公司所有。</p>
    </section>
    <div class="footer">
        <div class='footer-left'>
            <div class='footer-title'>甲方：</div>
            <div class='left-img'>
                <img src="@/assets/views/gallery/zhengshu.png" alt="">
            </div>
        </div>
        <div class='footer-right'>
             <div class='footer-title'>乙方：</div>
            <div class='right-img'>
                 <img :src="imghand" alt />
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import "@/sass/views/gallery/securityabouts.scss";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      imghand: ""
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    this.getUserinfo();
  },
  methods: {
    getUserinfo() {
      this.$siaxios
        .get(`/login/realNameAuthInfo/${this.userInfo.id}`)
        .then(res => {
          this.imghand = res.signatureImg;
         
        });
    }
  }
};
</script>
