<template>
  <div class="balance-index">
    <div class="balance-con">
      <div class="my-money">
        <div class="title">账户余额（元）</div>
        <div class="money">2000.00</div>
        <div class="withdraw-btn" @click="gowithdraw()">提现</div>
      </div>
      <img src="@/assets/views/mine/balance-bg.png" class="balance-bg" alt="">
    </div>
    <div class="order-list">
      <div class="title">订单信息</div>
      <div class="item">
        <div class="info">
          <div class="name">退款</div>
          <div class="time">2018-09-91 12:22:22</div>
        </div>
        <div class="price">+20000.00</div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/sass/views/mine/balance.scss"
import { mapGetters } from "vuex";
export default {
  name:'',
  data() {
    return {
      name:'',
    }
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted () {

  },
  methods: {
    /**
     * 提现
     */
    gowithdraw() {

    }
  }
}
</script>