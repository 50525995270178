<!--
 * @Description: 首页搜索
 * @Autor: bjp
--> 
<template>
    <div class="indexSearch">
        <div class="search">
            <div class="searchInput">
                <input type="text" placeholder="请输入要搜索的业务名称" v-model="searchContent" />
                <img src="@/assets/clean.png" alt @click="clean()" />
            </div>
            <span class="cancel" @click="$router.forward('/index/index')">取消</span>
        </div>
        <!-- 默认展示业务 -->
        <div class="common_business" v-if="!searchContent && !searchOver">
            <ul>
                <!-- <li v-for="item in commonList" :key="item.id" @click="getList(item.value)">{{item.title}}</li> -->
                <li v-for="item in commonList" :key="item.id" @click="$router.forward(item.path)">{{item.title}}</li>
            </ul>
        </div>
        <!-- 搜索结果展示 -->
        <div class="bus-list" v-if="showData.length > 0">
            <div class="item" v-for="item in showData" :key="item.id" @click="goOrder(item)">
                <img :src="item.listimgurl" class="bus-img" alt />
                <div class="bus-info">
                    <div class="title">{{item.businessname}}</div>
                    <div class="introduce">{{item.description}}</div>
                    <div class="price">¥{{item.amout}}起</div>
                </div>
            </div>
        </div>
        <!-- 暂无业务 -->
        <div class="no_business" v-if="showData.length <= 0 && searchContent && searchOver">
            <img src="@/assets/no_business.png" alt />
            <p>暂无业务</p>
            <p>暂无相关业务搜索</p>
        </div>
    </div>
</template>
<script>
import "@/sass/views/index/indexSearch.scss";
import { mapGetters } from "vuex";
export default {
    name: 'IndexSearch',
    data() {
        return {
            searchContent: "",
            searchOver: false, //接口是否完成
            commonList: [
                // {id:0,title:'全部业务',value:'0'},
                { id: 1, title: "毕业生专区", value: "毕业生", path: '/gallery/graduatefw' },
                { id: 2, title: "社保专区", value: "社保", path: '/gallery/securityfw' },
                { id: 3, title: "公积金专区", value: "公积金", path: '/gallery/gjjfuwu' },
                { id: 4, title: "档案业务专区", value: "档案", path: '/gallery/daguanli' },
                { id: 5, title: "户口业务专区", value: "户口", path: '/gallery/hkguanli' },
                { id: 6, title: "就业政策专区", value: "就业", path: '/classify/employment' },
                { id: 7, title: "创业政策专区", value: "创业", path: '/classify/entrepreneurship' }
            ],
            showData: []
        };
    },
    watch: {
        searchContent(val) {
            if (!val) {
                this.clean();
            } else {
                this.getList(val);
            }
        }
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo"
        })
    },
    methods: {
        clean() {
            this.searchContent = "";
            this.searchOver = false;
            this.showData = [];
        },
        async getList(val) {
            if (val != 0) {
                this.searchContent = val;
            } else {
                this.searchContent = "";
            }
            let flag = await this.$axios.get(
                "/homepage/getBusiness?businessname=" + this.searchContent
            );
            this.searchOver = true;
            if (!flag.data.success) {
                this.showData = [];
            } else {
                this.showData = flag.data.data;
            }
        },
        /**
         * 去创建订单  type 0社保1公积金2社保续费3公积金续费
         */
        goOrder(item) {
            if (item.id == 1) {
                this.$router.forward({
                    path: "/creatorder/socialsecurity",
                    query: { type: 0 }
                });
            } else if (item.id == 13) {
                this.$router.forward({
                    path: "/creatorder/accumulationfund",
                    query: { type: 1 }
                });
            } else {
                this.$router.forward({ path: "/creatorder/general", query: { id: item.id, businessname: item.businessname } });
            }
        }
    }
};
</script>
