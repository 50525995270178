<template>
    <div class="securityfw">
        <div class="Img">
            <img src="@/assets/views/gallery/shebaofw/HEAD.png" alt />
            <div class="Img1">专业人力资源服务方案</div>
            <div class="Img2">一对一办理</div>
            <div class="Img3"></div>
        </div>
        <ul class="three">
            <li @click="goExchange">
                <img src="@/assets/views/gallery/shebaofw/head1.png" alt />
                <div>社保官网</div>
            </li>
            <li @click="$router.forward({path:'/classify/list',query: { id: 0}})">
                <img src="@/assets/views/gallery/shebaofw/head2.png" alt />
                <div>社保服务</div>
            </li>
            <li @click="$router.forward({path:'/socialSecurity/list'})">
                <img src="@/assets/views/gallery/shebaofw/head3.png" alt />
                <div>社保问问</div>
            </li>
        </ul>
        <div class="Identification">
            <div></div>
            <div>服务内容</div>
        </div>
        <div class="swiper-container swiper-container-initialized swiper-container-horizontal banner">
            <div class="swiper-wrapper">
                <div class="swiper-slide swiper-slide-active">
                    <img src="@/assets/views/gallery/shebaofw/1.png" alt />
                </div>
                <div class="swiper-slide swiper-slide-next">
                    <img src="@/assets/views/gallery/shebaofw/2.png" alt />
                </div>
                <div class="swiper-slide">
                    <img src="@/assets/views/gallery/shebaofw/3.png" alt />
                </div>
            </div>
            <div class="swiper-pagination swiper-pagination-bullets">
                <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                <span class="swiper-pagination-bullet"></span>
                <span class="swiper-pagination-bullet"></span>
            </div>
        </div>
        <ul class="table">
            <li class="content" @click="$router.forward({path:'/creatorder/socialsecurity',query:{type:0}})">
                <div>
                    <img src="@/assets/views/gallery/shebaofw/center1.png" alt />
                </div>
                <ul>
                    <li>社保代缴</li>
                    <li>随时随地代缴社保</li>
                </ul>
            </li>
            <li class="content" @click="$router.forward({path:'/creatorder/general',query:{id:2,businessname:'社保补缴'}})">
                <div>
                    <img src="@/assets/views/gallery/shebaofw/center2.png" alt />
                </div>
                <ul>
                    <li>社保补缴</li>
                    <li>社保断缴来补救!</li>
                </ul>
            </li>
            <li class="content" @click="$router.forward({path:'/creatorder/general',query:{id:3,businessname:'社保转移'}})">
                <div>
                    <img src="@/assets/views/gallery/shebaofw/center3.png" alt />
                </div>
                <ul>
                    <li>社保转移</li>
                    <li>转移社保&nbsp;一键就好</li>
                </ul>
            </li>
            <li class="content" @click="$router.forward({path:'/creatorder/general',query:{id:4,businessname:'社保基本信息变更'}})">
                <div>
                    <img src="@/assets/views/gallery/shebaofw/center4.png" alt />
                </div>
                <ul>
                    <li>社保基本信息变更</li>
                    <li>社保有变?轻松更改！</li>
                </ul>
            </li>
            <li class="content" @click="$router.forward({path:'/creatorder/general',query:{id:5,businessname:'养老认证'}})">
                <div>
                    <img src="@/assets/views/gallery/shebaofw/center5.png" alt />
                </div>
                <ul>
                    <li>养老认证</li>
                    <li>最快认证&nbsp;养老金还会远吗</li>
                </ul>
            </li>
            <li class="content" @click="$router.forward({path:'/classify/list'})">
                <div>
                    <img src="@/assets/views/gallery/shebaofw/center6.png" alt />
                </div>
                <ul>
                    <li>更多服务</li>
                    <li>便捷服务&nbsp;发现更多</li>
                </ul>
            </li>
        </ul>
        <div class="interval"></div>
        <div class="Identification1">
            <div></div>
            <div>政策解读</div>
        </div>
        <div class="swiperbox">
            <div class="swiper">
                <div class="ul1">
                    <li class="li1">
                        <img src="@/assets/views/gallery/shebaofw/new1.png" alt />
                    </li>
                    <li class="li2">买房政策</li>
                    <li class="li3">外地户口买房资质</li>
                    <li class="li4"></li>
                    <li class="li5" @click="buyhouse(cityid)">查看详情</li>
                </div>
                <div class="ul1">
                    <li class="li1">
                        <img src="@/assets/views/gallery/shebaofw/new2.png" alt />
                    </li>
                    <li class="li2">子女上学</li>
                    <li class="li3">需父母在本市缴纳社保</li>
                    <li class="li4"></li>
                    <li class="li6" @click="childSchool(cityid)">查看详情</li>
                </div>
                <div class="ul1">
                    <li class="li1">
                        <img src="@/assets/views/gallery/shebaofw/new3.png" alt />
                    </li>
                    <li class="li2">落户政策</li>
                    <li class="li3">想积分落户、人才引进的硬性条件</li>
                    <li class="li4"></li>
                    <li class="li7" @click="settlePeople(cityid)">查看详情</li>
                </div>
            </div>
        </div>

        <div class="interval1"></div>
        <div class="Identification">
            <div></div>
            <div>服务优势</div>
        </div>
        <div class="Bot">
            <ul class="table1">
                <li class="content1">
                    <div>
                        <img src="@/assets/views/gallery/Accumulation/Bed1.png" alt />
                    </div>
                    <ul>
                        <li>动态随时查</li>
                        <li>参保随时查，动态全透明</li>
                    </ul>
                </li>
                <li class="content1">
                    <div>
                        <img src="@/assets/views/gallery/Accumulation/Bed2.png" alt />
                    </div>
                    <ul>
                        <li>安全更可靠</li>
                        <li>不断缴，不漏缴，零风险</li>
                    </ul>
                </li>
                <li class="content1">
                    <div>
                        <img src="@/assets/views/gallery/Accumulation/Bed3.png" alt />
                    </div>
                    <ul>
                        <li>社保随时缴</li>
                        <li>随时随地&nbsp;操作更自由</li>
                    </ul>
                </li>
                <li class="content1">
                    <div>
                        <img src="@/assets/views/gallery/Accumulation/Bed4.png" alt />
                    </div>
                    <ul>
                        <li>缴纳速度快</li>
                        <li>微信扫一扫，三分钟缴纳</li>
                    </ul>
                </li>
                <li class="content1">
                    <div>
                        <img src="@/assets/views/gallery/Accumulation/Bed5.png" alt />
                    </div>
                    <ul>
                        <li>服务覆盖广</li>
                        <li>多个城市开通业务</li>
                    </ul>
                </li>
                <li class="content1">
                    <div>
                        <img src="@/assets/views/gallery/Accumulation/Bed6.png" alt />
                    </div>
                    <ul>
                        <li>答疑更专业</li>
                        <li>人社专家&nbsp;资深答疑团队</li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper";
import "@/sass/views/gallery/securityfw.scss";
import { mapGetters } from "vuex";
export default {
    name: "Securityfw",
    data() {
        return {
            banner: [],
            cityid: "",
            baseInfosURL: ""
        };
    },
    created() {
        this.cityid = this.$route.query.cityid;
        this.$nextTick(() => {
            new Swiper(".banner", {
                pagination: {
                    el: ".swiper-pagination"
                },
                loop: true
            });
        });
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo"
        })
    },
    mounted() {
        this.getBaseInfo();
    },
    methods: {
        /**
         * 跳转外部链接
         */
        goExchange() {
            if (this.$isPlus) {
                /* eslint-disable */
                uni.navigateTo({
                    url: '/pages/index/otherLike?link='+this.baseInfosURL
                });
            } else {
                window.location.href = this.baseInfosURL;
            }

        },
        /**
         * 设置社保外链
         */
        getBaseInfo() {
            this.baseInfosURL = "http://12333.qingdao.gov.cn/grcx2/";
            if (!this.userInfo.cityid) return
            this.$siaxios
                .get(`/order/getCityBase/${this.userInfo.cityid}`)
                .then(res => {
                    if (res) this.baseInfosURL = res.shebaourl;
                });
        },
        //买房政策
        buyhouse(cityid) {
            if (cityid == 170) {
                this.$router.forward("/mine/jinan-buyhouse"); //济南
            } else if (cityid == 175) {
                this.$router.forward("/mine/buyhouse"); //烟台
            } else {
                this.$router.forward("/gallery/QD-buyhouse"); //青岛
            }
        },
        //子女上学
        childSchool(cityid) {
            if (cityid == 170) {
                this.$router.forward("/mine/jinan-school"); //济南
            } else if (cityid == 175) {
                this.$router.forward("/mine/school"); //烟台
            } else {
                this.$router.forward("/gallery/QD-school"); //青岛
            }
        },
        //落户政策
        settlePeople(cityid) {
            if (cityid == 170) {
                this.$router.forward("/mine/jinan-luohu"); //济南
            } else {
                this.$router.forward("/gallery/QD-residence"); //青岛
            }
        }
    }
};
</script>