<template>
  <div class="employment-index">
    <div class="item" @click="Jump1">
       <img src="@/assets/views/classify/employment1.png" class="item-icon" alt="">
       <div class="e-info">
         <div class="title">在青就业高校毕业生住房补贴 </div>
         <div class="tips">最高1500元/月 最长36个月</div>
       </div>
    </div>
    <div class="item" @click="Jump2">
       <img src="@/assets/views/classify/employment2.png" class="item-icon" alt="">
       <div class="e-info">
         <div class="title">青年人才在青创新创业一次性安家费 </div>
         <div class="tips">博士研究生15万元/人、硕士研究生10万元/人</div>
       </div>
    </div>
    <div class="item" @click="Jump3">
       <img src="@/assets/views/classify/employment3.png" class="item-icon" alt="">
       <div class="e-info">
         <div class="title">高校毕业生小微企业就业补贴 </div>
         <div class="tips">每人每月400元，最长可领36个月</div>
       </div>
    </div>
    <div class="item" @click="Jump4">
       <img src="@/assets/views/classify/employment4.png" class="item-icon" alt="">
       <div class="e-info">
         <div class="title">用人单位吸纳就业社会保险补贴和岗位补贴 </div>
         <div class="tips">每人每月200元，最长可领3年</div>
       </div>
    </div>
    <div class="item" @click="Jump5">
       <img src="@/assets/views/classify/employment5.png" class="item-icon" alt="">
       <div class="e-info">
         <div class="title">灵活就业社会保险补贴 </div>
         <div class="tips">每人每月最高500元，补贴期限为3年</div>
       </div>
    </div>
    <div class="item" @click="Jump6">
       <img src="@/assets/views/classify/employment6.png" class="item-icon" alt="">
       <div class="e-info">
         <div class="title">“三支一扶”计划</div>
         <div class="tips">适用农村基层的高校毕业生</div>
       </div>
    </div>
    <div class="item" @click="Jump7">
       <img src="@/assets/views/classify/employment7.png" class="item-icon" alt="">
       <div class="e-info">
         <div class="title">大学生基层公共管理和社会服务岗位  </div>
         <div class="tips">平均工资标准的80%给予工作补贴</div>
       </div>
    </div>
    <div class="item" @click="Jump8">
       <img src="@/assets/views/classify/employment8.png" class="item-icon" alt="">
       <div class="e-info">
         <div class="title">青年就业见习补贴</div>
         <div class="tips">按照最低工资标的50%给予见习基地补贴</div>
       </div>
    </div>
    <div class="item" @click="Jump9">
       <img src="@/assets/views/classify/employment9.png" class="item-icon" alt="">
       <div class="e-info">
         <div class="title">在青高校在校大学生就业能力提升补贴 </div>
         <div class="tips">可获1000元-2000元补贴标准</div>
       </div>
    </div>
    <div class="item" @click="Jump10">
       <img src="@/assets/views/classify/employment10.png" class="item-icon" alt="">
       <div class="e-info">
         <div class="title">大学生综合职业能力培训补贴 </div>
         <div class="tips">每人最高1000元</div>
       </div>
    </div>
    <div class="showService" @click="showConsult()">
      立即咨询
    </div>
  </div>
</template>

<script>
import "@/sass/views/classify/employment.scss";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods:{
    /**
     * 展示顾问
     */
    showConsult() {
      if (this.userInfo.id == "") {
        this.$router.forward("/login/login");
        return
      }
      this.$consult({ userid: this.userInfo.id })
    },
    Jump1(){
      this.$router.forward({path:'/jumpdetails/detaila'})
    },
    Jump2(){
      this.$router.forward({path:'/jumpdetails/detailb'})
    },
    Jump3(){
      this.$router.forward({path:'/jumpdetails/detailc'})
    },
    Jump4(){
      this.$router.forward({path:'/jumpdetails/detaild'})
    },
    Jump5(){
      this.$router.forward({path:'/jumpdetails/detaile'})
    },
    Jump6(){
      this.$router.forward({path:'/jumpdetails/detailf'})
    },
    Jump7(){
      this.$router.forward({path:'/jumpdetails/detailg'})
    },
    Jump8(){
      this.$router.forward({path:'/jumpdetails/detailh'})
    },
    Jump9(){
      this.$router.forward({path:'/jumpdetails/detaili'})
    },
    Jump10(){
      this.$router.forward({path:'/jumpdetails/detailj'})
    },
  }
}
</script>