<!--
 * @Description: 工伤保险-政策列表
 * @Autor: bjp
 -->
<template>
  <div class="pensionList">
    <my-pension state="3" type="1"></my-pension>
  </div>
</template>
<script>
import my_policy from "@/views/questions/common/PolicyList"
export default {
  components:{
    'my-pension':my_policy,
  },
  data(){
    return {

    }
  }
}
</script>

