<!--
 * @Description: 
 * @Autor: bjp
 -->
<template>
  <div class="commonPolicy">
    <!-- 下拉隐藏 -->
    <div @touchstart="touchstart" @touchmove="touchmove" ref="touchIt" class="touch" v-if="touchShow">
      <div class="isHide" @click="touchmove($event,-1)">
        <img src="@/assets/views/question/hide_arrow.png" alt />
        <span>下拉隐藏</span>
      </div>
      <my-pension :state="state" :type="type" judge="1" :refresh="false" :url="url"></my-pension>
    </div>
    <!-- 浮动 -->
    <div class="floating" ref="floating" style="opacity:0">
      <my-float :url="url"></my-float>
    </div>
  </div>
</template>
<script>
import my_policy from "./PolicyList"
import my_float from "./FloatWindow"
import "@/sass/views/question/commonPension.scss"
export default {
  components:{
    'my-pension':my_policy,
    'my-float':my_float
  },
  props:{
    url:{
      type:String,
      default:''
    },
    state:{
      type:String,
      default:''
    },
    type:{
      type:String,
      default:''
    },
  },
  data(){
    return{
      initY:'',
      moveY:'',
      isShow:true,
      touchShow:true,
    }
  },
  methods:{
    touchstart(ev){
      // ev.preventDefault()
      this.initY = ev.targetTouches[0].pageY
    },
    touchmove(ev,type){
      if(type && type < 0){
        this.moveY = type
      } else {
        ev.preventDefault()
        this.moveY = this.initY - ev.targetTouches[0].pageY
      }
      if(this.moveY < 0){
        this.$refs.touchIt.style.transform = 'translateY('+ this.$refs.touchIt.offsetHeight +'px)'
        this.$refs.floating.style.opacity = 1
      }
    },
    changeToLink(){
      this.touchShow = false
      this.$refs.floating.style.opacity = 1
    }
  }
}
</script>