<template>
  <div class="home-index">
    <div id="trigger4">单项选择</div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "index",
  data() {
    return {
      loadingFullscreen: "",
      keyword: "123",
      showData: {
        pageNum: 1,
        list: []
      }
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    this.$monthselect({
      saletypeid:1,
      callback: (month) => {
        console.log(month);
      }
    });
  }
};
</script>
