<template>
  <div>
    <div class="shengyubx">
      <img src="@/assets/views/gallery/Birth/head.png" alt="">
      <div class="Written"><span>——</span> 生育保险 <span>——</span></div>
      <div class="overlay"></div>
      <div class="ContBox">
        <div class="title">
          <div>
            <img src="@/assets/views/gallery/Bed1.png" alt="">
            <div>单位和个人缴费比例</div>
          </div>
        </div>
        <div class="card">
          <ul>
            <li class="one">单位缴费</li>
            <li class="two">个人缴费</li>
          </ul>
          <div>现与医疗合并缴费</div>
        </div>
        <div class="title1">
          <div>
            <img src="@/assets/views/gallery/Birth/Ped1.png" alt="">
            <div>生育保险报销条件</div>
          </div>
        </div>
        <ul class="specific">
          <li>符合国家计划生育政策生育或者实施计划生育手术</li>
          <li>初次参加职工医保连续缴费满6个月后享受生育医疗费待遇</li>
          <li>生育时连续缴纳医疗保险费满12个月，基本医疗保险基金按规定支付生育津贴;已参保但连续缴费不满1年的，待用人单位为职工连续足额缴费满1年后，由基本医疗保险基金补支职工生育津贴</li>
          <div></div>
        </ul>
        <div class="interval"></div>
        <div class="title2">
          <div>
            <img src="@/assets/views/gallery/Birth/Ped2.png" alt="">
            <div>产假天数</div>
          </div>
        </div>
        <!-- <img src="@/assets/views/gallery/Birth/List.png" alt=""> -->
        <div class="table">
          <div id="myChart"></div>
          <div class="jibie">级别</div>
          <div class="hide1">98+60</div>
          <div class="hide2">98+60<span>+15</span></div>
          <div class="hide3">
            98+60<span>+15</span>
          </div>
          <div class="hide4">(胎数-1)</div>
        </div>
        <ul class="formula">
          <li>领取公式：</li>
          <li><span>本人当年(或上月)生育保险个人缴费月工资基数</span>/<span>30</span>*<span>产假天数</span></li>
        </ul>
        <div class="interval"></div>
        <div class="title3">
          <div>
            <img src="@/assets/views/gallery/Birth/Ped3.png" alt="">
            <div>生育、计划生育医疗费用报销标准</div>
          </div>
        </div>
        <div class="bottom">
          <ul>
            <li>计生费（单位：元）</li>
            <li>放置节育器：350</li>
            <li>取出节育器：150</li>
            <li>皮下埋/取术：100</li>
            <li>住院终止妊娠：55</li>
            <li>输精管结扎：300</li>
            <li>输卵管结扎：2000</li>
          </ul>
          <ul>
            <li>住院最高报销</li>
            <li>自然分娩：2000</li>
            <li>人工分娩：2500</li>
            <li>刨宫产：3000</li>
            <li>刨宫产伴其他手术:3500</li>
            <li>因母婴住院终止妊娠:800</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 浮动 -->
    <common :url="url" ref="common" state="4" type="1"></common>
  </div>
</template>

<script>
import "@/sass/views/gallery/shenyubx.scss"
import common from "@/views/questions/common/CommonPolicy"
export default {
  components:{
    common
  },
  data(){
    return{
      url:'/gallery/shengyuPension',
    }
  },
  beforeRouteEnter(to,from,next){
    next( _this =>{
      if(from.path == _this.url){
        _this.$refs.common.changeToLink()
      }
    }
    )
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    // 基于准备好的dom，初始化echarts实例
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        backgroundColor: "#f5f5f5",
        color: ["#4162ff", "#16c2af", "#ffc751", "#ff6e72"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
        },
        // textStyle: {
        //   fontSize: '100%'
        // },
        legend: {
          data: ["产假", "奖励假", "难产假", "多胞胎"],
          padding:[30, 30,0 ,0],
        },
        grid: {
          //图表的位置
          top: "30%",
          left: "5%",
          right: "4%",
          bottom: "5%",
          containLabel: true
        },
        yAxis: [
          {
            type: "value",
            name: "天",
            nameTextStyle: {
              //---坐标轴名称样式
              color: "#999999",
              padding: [5, 0, 0, -15], //---坐标轴名称相对位置
              fontSize:"100%"
            },
            splitLine: { show: false },
            max: 200,
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#cccccc"
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize:"80%"
              },
            }
          }
        ],
        xAxis: [
          {
            type: "category",
            data: ["未满4个月流产", "未满4个月流产", "正常", "难产", "多胞胎"],
            splitLine: { show: false },
            namelocation: "end",
            nameTextStyle: {
              //---坐标轴名称样式
              color: "#999999",
              padding: [30, 0, 0, -400] //---坐标轴名称相对位置
            },
            
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#cccccc"
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize:"80%"
              },
              interval: 0, //代表显示所有x轴标签显示
              formatter: function(params) {
                var newParamsName = ""; // 最终拼接成的字符串
                var paramsNameNumber = params.length; // 实际标签的个数
                var provideNumber = 4; // 每行能显示的字的个数
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
                /**
                 * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                 */
                // 条件等同于rowNumber>1
                if (paramsNameNumber > provideNumber) {
                  /** 循环每一行,p表示行 */
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = ""; // 表示每一次截取的字符串
                    var start = p * provideNumber; // 开始截取的位置
                    var end = start + provideNumber; // 结束截取的位置
                    // 此处特殊处理最后一行的索引值
                    if (p == rowNumber - 1) {
                      // 最后一次不换行
                      tempStr = params.substring(start, paramsNameNumber);
                    } else {
                      // 每一次拼接字符串并换行
                      tempStr = params.substring(start, end) + "\n";
                    }
                    newParamsName += tempStr; // 最终拼成的字符串
                  }
                } else {
                  // 将旧标签的值赋给新标签
                  newParamsName = params;
                }
                //将最终的字符串返回
                return newParamsName;
              }
            }
          }
        ],

        nameTextStyle: {
          // 坐标轴名称样式
          color: "red",
          padding: [5, 0, 0, -5]
        },
        series: [
          {
            name: "产假",
            type: "bar",
            stack: "总量",
            label:{
              show:true,
              position:"top",
              fontSize:"100%"
            },
            barWidth: "60%",
            data: [15, 42, 98, 98, 98]
          },
          {
            name: "奖励假",
            type: "bar",
            stack: "总量",
            data: [0, 0, 60, 60, 60],
          },
          {
            name: "难产假",
            type: "bar",
            stack: "总量",
            data: [0, 0, 0, 15, 15],
          },
          {
            name: "多胞胎",
            type: "bar",
            stack: "总量",
            data: [0, 0, 0, 0, 15],
          }
        ]
      });
      setTimeout(function (){
        window.onresize = function () {
            myChart.resize();
          }
      },200)
    },
  },
}
</script>

<style>

</style>