<template>
  <div class="use-index">
    <div class="banner-con">
      <div class="banner">
        <div class="title-banner">档案很重要</div>
        <div class="banner-introduce">计算工龄、养老、职称评定、办理人事手续都离不开档案</div>
      </div>
    </div>
    <div class="main-business">
      <div class="bus-nav">
        <div class="navBarUl" ref="navBarUl">
          <span
            class="item"
            @click="getListByType(item.id,$event)"
            :class="{'active': cttrType == item.id}"
            v-for="item in typedata"
            :key="item.id"
          >{{item.name}}</span>
        </div>
      </div>
    </div>
    <template v-if="cttrType == 1">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <span>退休办理</span>
          </div>
          <div class="use-cont">
            <span>
             办理退休手续时，工龄和工资、待遇和职务以及社保缴纳年限等都是以个人档案的记录为依据。比如退休时需要依据档案认定个人出生时间，从而确定退休时间，需要确定个人参加工作的时间，从而确定开始缴费或视为同时缴费的时间，以机损养老金额。
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 2">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <span>工龄计算</span>
          </div>
          <div class="use-cont">
            <span>档案影响转正定级和计算工龄，从而影响养老金的数额。有很多毕业生找到工作后，没有及时办理参加工作的有关手续，几年后仍是学生身份，从而影响转正定级和工龄及退休金的计算。</span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 3">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <span>职称评审</span>
          </div>
          <div class="use-cont">
            <span>职称可评可考，一般具备职称评定条件的人不用参加全国通考，可以直接评定职称，和考试获得的职称具有同等效力。职称评审需要满足一定的条件。是否达到申报条件，学历、工作经历等需要通过人事档案中的证明材料来确认</span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 4">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <span>公务员政审</span>
          </div>
          <div class="use-cont">
            <span>考取公务员后，为了保证顺利录用，要进行政审。公务员政审主要考察家庭出身和本人的政治思想表现、有无受奖励或处分的情节、道德品质、直系血亲和对本人有重大影响旁系血亲的政治历史情况等等。公务员政审的程序一般是主管局派人到你单位组织召开座谈会，了解相关情况；2、调查你的档案中是否有受处分及刑事处罚的纪录。政审合格后，单位决定正式录取你后，会到你的档案存放地提取你的个人档案。所以，如果打算考取公务员，个人档案一定要保存好，并且不能有违法乱纪的处分。</span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 5">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <span>户籍调动</span>
          </div>
          <div class="use-cont">
            <span>有些城市在办理人才引进、户口调动时需要调动人事档案。比如2018年天津海河英才人才落户计划。当时许多在北京工作无法解决户口问题的人为了孩子的教育选择把户口落在天津。而天津人才引进落户要求满足学历、在天津无房又无工作的人需要先将档案调到天津才可以办理人才落户手续。</span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 6">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <span>人事手续办理</span>
          </div>
          <div class="use-cont">
            <span>目前入职一些单位比如国企、央企、事业单位，对人事档案的要求仍然非常严格，没有人事档案无法正常入职。单位录用之前首先是对与之前的学历以及工作经历作为审查目标，然后就是档案调到单位后，进行资料填充，以后每年会有年度考核表制，奖励情况等资料加进去，如果有工作调动，档案也会随着人事关系一起调动。 如果没有人事档案，这些工作都无法进行。</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import "@/sass/views/mine/archives-use.scss";
export default {
  name: "archives-use",
  data() {
    return {
      typedata: [
        {
          id: 1,
          name: "退休办理"
        },
        {
          id: 2,
          name: "工龄计算"
        },
        {
          id: 3,
          name: "职称评审"
        },
        {
          id: 4,
          name: "公务员政审"
        },
        {
          id: 5,
          name: "户籍调动"
        },
        {
          id: 6,
          name: "人事手续办理"
        }
      ],
      cttrType: this.$route.query.id
    };
  },
  mounted() {
    this.iniBusiness();
    this.getListByType();
  },
  methods: {
    /**
     * 初始化业务大类滚动
     */
    /* eslint-disable */
    iniBusiness() {
      this.$nextTick(() => {
        let winWidth = document.documentElement.clientWidth;
        let navBarUl = this.$refs.navBarUl;
        let oLi = navBarUl.getElementsByClassName("item");
        navBarUl.style.width = "3000px";
        var allListWidth = 0;
        for (let i = 0; i < oLi.length; i++) {
          allListWidth += oLi[i].offsetWidth;
        }
        if (this.cttrType >= 4) {
          navBarUl.style.marginLeft = "-273" + "px";
        }
        navBarUl.style.width = allListWidth + 120 + "px";
        let startX = 0;
        let cuttrX = 0;
        navBarUl.style.transition = "all .5s";
        navBarUl.addEventListener("touchstart", e => {
          //e就是事件
          startX = e.targetTouches[0].pageX;
          cuttrX = navBarUl.offsetLeft;
        });
        navBarUl.addEventListener("touchmove", e => {
          let leftpull = winWidth - allListWidth;
          if (navBarUl.style.transition.split(" ")[0] != "none") {
            navBarUl.style.transition = "none";
          }
          //移动的差值
          let tempRutn = e.targetTouches[0].pageX - startX;
          //nav距左位置
          let mlNum = cuttrX + tempRutn;
          if ((mlNum >= 0 && tempRutn > 0) || leftpull > 0) {
            navBarUl.style.marginLeft = "0";
          } else if (tempRutn < 0 && navBarUl.offsetLeft <= leftpull) {
            navBarUl.style.marginLeft = leftpull - 120 + "px";
          } else {
            navBarUl.style.marginLeft = cuttrX + tempRutn + "px";
          }
          navBarUl.addEventListener("touchend", e => {
            //e就是事件
            navBarUl.style.transition = "all .5s";
          });
        });
        // navBar点击
        for (let i = 0; i < oLi.length; i++) {
          oLi[i].onclick = function() {
            let nBLeft = navBarUl.offsetLeft;
            let nBWidth = navBarUl.offsetWidth;
            let maxML = -nBWidth + winWidth;
            let mlDistance = nBLeft - this.offsetLeft+this.offsetWidth;
            if (mlDistance > 0) {
              navBarUl.style.marginLeft = 0;
            } else if (mlDistance <= maxML) {
              navBarUl.style.marginLeft = maxML + "px";
            } else {
              navBarUl.style.marginLeft = mlDistance + "px";
            }
          };
        }
      });
    },
    getListByType(id) {
      if (id) this.cttrType = id;
    }
  }
};
</script>

