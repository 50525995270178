<template>
  <div class="security">
    <div class="title">档管家公积金服务协议</div>
    <section class="rule">
      <h3>提示条款</h3>
      <p class="s1-title">欢迎您与档管家共同签署《档管家公积金服务协议》（以下简称“本协议”）并使用档管家平台服务！</p>
      <p class="s1-title">各服务条款所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。</p>
      <p class="s1-title">【审慎阅读】您在公积金服务模块中点击同意本协议之前，应当认真阅读本协议。请您无别审慎阅读、充分理解各条款内容。</p>
      <p
        class="s1-title"
      >【签约动作】当您在本页面下填写信息、阅读并同意本协议后，即表示您已充分阅读、理解并接受本协议的全部内容，并与档管家达成一致。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止使用本程序。</p>
    </section>
    <section class="rule">
      <h3>一、服务内容</h3>
      <p class="s1-title">1、公积金代缴服务</p>
      <p class="s1-title">系指您与档管家签订网上协议，由档管家按照国家规定为您缴纳公积金的服务。</p>
      <p class="s1-title">2、公积金补缴服务</p>
      <p class="s1-title">系指您与档管家签订网上协议，由档管家按照国家规定的补缴范围为您补公积金的服务。</p>
      <p class="s1-title">3、公积金转移服务</p>
      <p class="s1-title">系指您与档管家签订网上协议，由档管家协助您将外地的公积金转入本地或将本地缴纳的公积金转出到外地的服务。</p>
      <p class="s1-title">4、公积金提取服务</p>
      <p class="s1-title">系指您与档管家签订网上协议，由档管家按照当地政府及公积金管理中心的提取条件规定，为您提取之前缴存在您公积金账户的余额的服务。</p>
    </section>
    <section class="rule">
      <h3>二、特别约定</h3>
      <p
        class="s1-title"
      >1、您所选择的所有公积金服务业务，均是委托与被委托的关系，不存在事实上的劳动关系，故您无需实际上为档管家提供劳动，档管家也无需向您发放工资和提供任何的劳动待遇。</p>
      <p
        class="s1-title"
      >2、档管家为您仅提供代理服务，一切基于劳动关系所产生的费用，包括但不限于补偿金、赔偿金、工伤、医疗等费用以及所产生的相关责任都由您自行承担，与档管家无关。</p>
      <p class="s1-title">3、基于您与档管家的委托与受托关系，档管家不能为您开具包括但不限于在职证明、离职证明、收入证明等有关劳动关系的书面材料</p>
      <p class="s1-title">4、您在办理相关业务时，应积极配合提供相关证明或资料。因不提供相关证明或不配合办理引发纠纷的，将由您自行承担相关责任。</p>
      <p class="s1-title">5、您在档管家平台选择公积金服务，所有涉及您公积金基数、月缴存额度等数据都是您自行确认的，档管家将根据您在平台填写的相关数据为您提供代缴服务。</p>
      <p class="s1-title">6、公积金基数及月缴存额度每年会有相应程度的上浮，上浮标准根据当地人社局所发的具体政策为准，涉及到您在缴纳过程中的差额，档管家将统一进行补收。</p>
      <p
        class="s1-title"
      >7、您有权随时终止代缴公积金服务，请您在每月25日之前（遇法定节假日提前）将您的终止服务请求在平台提交，如晚于约定日期提交的，将生成当月费用，此费用由您自行承担。</p>
    </section>

    <section class="rule">
      <h3>三、其他</h3>
      <p class="s1-title">本协议未尽事宜，根据我国相关法律、法规以及毕业生分配办事处相关业务规定办理。</p>
      <p class="s1-title">本协议条款最终解释权归青岛中海智库人力资源服务有限公司所有。</p>
    </section>
    <div class="footer">
      <div class="footer-left">
        <div class="footer-title">甲方：</div>
        <div class="left-img">
          <img src="@/assets/views/gallery/zhengshu.png" alt />
        </div>
      </div>
      <div class="footer-right">
        <div class="footer-title">乙方：</div>
        <div class="right-img">
          <img :src="imghand" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/sass/views/gallery/securityabouts.scss";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      imghand: ""
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    this.getUserinfo();
  },
  methods: {
    getUserinfo() {
      this.$siaxios
        .get(`/login/realNameAuthInfo/${this.userInfo.id}`)
        .then(res => {
          this.imghand = res.signatureImg;
         
        });
    }
  }
};
</script>
