<template>
  <div class="socialsecurity-index">
    <div class="general-top" style="padding-bottom:20px">
      <div class="banner-con">
        <div class="swiper-container" id="swiper1">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="item in showInfo.businessinfo.commodityimgs"
              :key="item.id"
            >
              <img :src="item.commodityimgurl" alt />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div class="title">{{showInfo.businessconfig.businessname}}</div>
      <div class="general-tips">{{showInfo.businessconfig.description}}</div>
      <div class="general-money">
        ￥
        <span class="general-price">{{param.price}}</span> 起
      </div>
    </div>
    <div class="order-gray-line"></div>
    <!--        劳动咨询-->
    <Saletype46 />
    <div class="order-gray-line"></div>
    <Saletype46_ />
    <div class="bottom-menu">
      <div class="order-btn full" v-if="freeCount===true" @click="goService()">免费咨询</div>
      <div class="order-btn full" v-if="freeCount>0" @click="useCount()">(免费次数{{freeCount}})免费咨询</div>
      <div class="order-btn full" v-if="freeCount===0" @click="nextStemp()">下一步</div>
    </div>
  </div>
</template>
<script>
import "@/sass/views/creatorder/socialsecurity.scss";
import Saletype46 from "./business-introduction/saletypeid-46/introduce"; //劳动咨询
import Saletype46_ from "./business-introduction/saletypeid-46/problem"; //劳动咨询
import { mapGetters } from "vuex";
import Swiper from "swiper";

export default {
  name: "CreatorderConsult",
  data() {
    return {
      serviceUrl: "",
      param: {
        saletypeid: 46,
        cityid: 1,
        cityname: "",
        settlemodeid: "",
        price: "0.00" //订单预期金额
      },
      shoufei: "", //咨询下单费用
      freeCount: 0, //true 当天免费 数字为免费类型 0需要下单购买咨询次数
      showInfo: {
        businessconfig: {},
        businessinfo: {}
      }
    };
  },
  created() {
    this.serviceUrl = `https://im.7x24cc.com/phone_webChat.html?accountId=N000000019197&chatId=025fde47-8010-4554-be25-eea1fb499763&agentExten=8002&phone=${this.userInfo.tel}&nickName=${this.userInfo.name}`;
    this.getDescribe();
    this.selectFree();
  },
  mounted() {
    this.param.userid = this.userInfo.id;
    this.iniPrice();
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  components: {
    Saletype46, //高校毕业生档案
    Saletype46_ //高校毕业生档案流程
  },
  methods: {
    /**
     * 获取业务介绍
     */
    getDescribe() {
      this.$siaxios
        .get(`/homepage/detail/${this.param.saletypeid}`)
        .then(res => {
          this.showInfo = res;
          this.param.businessname = res.businessconfig.businessname;
          this.param.listimgurl = res.businessinfo.listimgurl;
          this.iniSwiper();
        });
    },
    /**
     * 查询当前时间是否免费
     */
    selectFree() {
      if (!this.userInfo.id) return;
      this.$siaxios.get(`/consult/getstate/${this.userInfo.id}`).then(res => {
        if (typeof res == "object") {
          //返回对象当前时间免费
          this.freeCount = true;
        } else {
          this.freeCount = res;
        }
      });
    },
    /**
     * 初始化金额
     */
    iniPrice() {
      this.$siaxios
        .get(
          `common/getBusinessCharge/1/${this.param.saletypeid}`
        )
        .then(res => {
          let busche = res[0];
          this.shoufei = busche.shoufei;
          if (busche.chargetype == 2) this.param.price = busche.shoufei;
          else this.param.price = busche.shoufu;
        });
    },
    /**
     * 是否可以继续下单
     */
    isGon() {
      if (!this.userInfo.id) {
        this.$router.forward("/login/login");
        return true;
      }

      if (this.userInfo.renzhengstate != 1) {
        this.$confirm({
          describe: "办理业务请先认证，是否跳转认证页面",
          callback: boolean => {
            if (boolean) this.$router.forward("/mine/authentication");
          }
        });
        return true;
      }
      return false;
    },
    /**
     * 初始banner
     */
    iniSwiper() {
      this.$nextTick(() => {
        new Swiper("#swiper1", {
          slidesPerView: 1, //每屏显示的个数
          spaceBetween: 30, //间隔距离
          pagination: {
            el: ".swiper-pagination",
            clickable: true
          },
          autoplay: true
        });
      });
    },
    /**
     * 下单添加次数
     */
    nextStemp() {
      if (this.isGon()) return;
      this.$siaxios.post(`/order/saveConsult`, this.param, false).then(res => {
        this.$router.forward({
          path: "/creatorder/orderdetail",
          query: { id: res.id }
        });
      });
    },
    /**
     * 使用次数
     */
    useCount() {
      if (this.isGon()) return;
      this.$siaxios.get("consult/usenum/" + this.userInfo.id).then(() => {
        this.goService();
      });
    },
    goService() {
      if (this.isGon()) return;
      /* eslint-disable */
      _53App.getApp('chat').openSdkUrl('float');
    }
  }
};
</script>
