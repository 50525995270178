<template>
  <div class="socialsecurity-index">
    <div class="pay-money">
      <div class="title">缴费金额</div>
      <div class="money">
        {{allmoney}}
        <span>&nbsp;元/月</span>
      </div>
    </div>
    <div class="tips">超过当月{{baseInfos.latestpayment}}号,只能办理下月时间</div>
    <div class="order-content">
      <div class="info-line">
        <div>
          <span>*</span>缴纳城市
        </div>
        <div class="select-style">{{param.sjgjOrderShebaoGongjijin.city}}</div>
      </div>
      <div class="info-line">
        <div>
          <span>*</span>公积金基数
        </div>
        <div class="select-style">{{param.sjgjOrderShebaoGongjijin.paybase}}</div>
      </div>
      <div class="info-line">
        <div>
          <span>*</span>开始缴纳月份
        </div>
        <div class="select-style">{{param.sjgjOrderShebaoGongjijin.startmonth}}</div>
      </div>
      <div class="info-line">
        <div>
          <span>*</span>缴纳月份
        </div>
        <div class="select-style" ref="duration" @click="showMonthSelect()">{{shebaoSelectShow}}</div>
        <img class="right" src="@/assets/right.png" alt />
      </div>
      <div class="info-line">
        <div>
          <span>*</span>联系电话
        </div>
        <div class="select-style">
          <input
            class="normal-input"
            v-model="param.sjgjOrderShebaoGongjijin.tel"
            type="text"
            placeholder="请输入联系电话"
          />
        </div>
      </div>
      <div class="reason-line pb85">
        <div>备注</div>
        <textarea
          v-model="param.sjgjOrderShebaoGongjijin.remark"
          name
          id
          rows="5"
          placeholder="请输入备注"
        ></textarea>
      </div>
    </div>
    <comintroduce />
    <div class="bottom-menu">
      <div class="consult" @click="showConsult()">
        <img src="@/assets/zaixian.png" class="consult-icon" alt />咨询
      </div>
      <div class="order-btn" @click="nextStemp()">下一步</div>
    </div>
  </div>
</template>

<script>
import "@/sass/views/creatorder/socialsecurity.scss";
import MobileSelect from "@/components/plug-in/mobile-select";
import comintroduce from "../business-introduction/saletypeid-1/introduce"; //公积金详情
import { mapGetters } from "vuex";
export default {
  name: "Socialsecurity",
  data() {
    return {
      showType: 1, //1购买流程 2平台优势 3常见问题 4温馨提示
      serviceMoney: 0,
      baseInfos: {
        //基本信息
        sjgjHouseHoldRegisters: []
      },
      businessCharge: [],
      shebaoLength: 0, //缴纳社保的月数
      shebaoSelectShow: "",
      showInfo: "", //业务介绍
      shebaoMonth: [], //可缴纳社保月份
      natureSelect: null, //户籍性质下拉
      param: {
        saletypeid: 13, //类型
        userid: "", //用户id
        remark: "", //备注
        sjgjOrderShebaoGongjijin: {
          paymonth: "", //缴纳月份
          type: this.$route.query.type,
          cityid: "",
          city: "青岛市",
          startmonth: "", //开始月份
          overmonth: "", //结束月份
          paybase: 5000, //公积金基数
          remark: "", //备注
          tel: ""
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    }),
    allmoney() {
        let tempMoney = this.param.sjgjOrderShebaoGongjijin.paybase *
          (this.baseInfos.accumulationpeople +
            this.baseInfos.accumulationcompay) +
          this.serviceMoney || 0
        return   Number(tempMoney).toFixed(2);
    }
  },
  mounted() {
    if (this.userInfo.id) {
      this.param.userid = this.userInfo.id;
      this.param.sjgjOrderShebaoGongjijin.tel = this.userInfo.tel;
      this.getSheBaoMonth();
    } else {
      this.iniNoneUserData();
    }
  },
  components: {
    comintroduce
  },
  methods: {
    /**
     * 获取当前年月
     */
    iniNoneUserData() {
      //设置展示数据
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let startTime = `${y}-${m}`;

      this.param.sjgjOrderShebaoGongjijin = {
        paymonth: "", //缴纳月份
        type: this.$route.query.type,
        cityid: "",
        city: "青岛市",
        startmonth: startTime, //开始月份
        overmonth: "", //结束月份
        paybase: 5000, //公积金基数
        remark: "", //备注
        tel: ""
      };
      // 初始化下拉框
      let showBusinessCharge = ["1个月", "3个月", "6个月", "12个月"];
      new MobileSelect({
        trigger: this.$refs.duration,
        title: "缴纳月份",
        wheels: [{ data: showBusinessCharge }]
      });
    },
    /**
     * 判断是否是续缴
     */
    isXujiao() {
      this.$siaxios
        .get(`/order/isXujiao?userid=${this.userInfo.id}&type=2`)
        .then(res => {
          if (res == null || res.states == 4) {
            this.param.sjgjOrderShebaoGongjijin.type = 1;
          } else if (res.states == 1 || res.states == 2 || res.states == 3) {
            this.param.sjgjOrderShebaoGongjijin.type = 3;
          }
        });
    },
    /**
     * 初始化下拉框
     */
    async iniSelect() {
      if (this.param.sjgjOrderShebaoGongjijin.cityid) {
        this.businessCharge = await this.$siaxios.get(
          `common/getBusinessCharge/${this.param.sjgjOrderShebaoGongjijin.cityid}/${this.param.saletypeid}`
        );
        this.businessCharge.forEach(element => {
          element.value = `${element.taocan}个月(￥${element.shoufei}元)`;
        });
        let maxIndex;
        if (this.shebaoLength > 12) {
          maxIndex = 3;
        } else if (this.shebaoLength > 6) {
          maxIndex = 2;
        } else if (this.shebaoLength > 3) {
          maxIndex = 1;
        } else {
          maxIndex = 0;
        }
        this.shebaoSelectShow = this.businessCharge[
          maxIndex
        ].value;
        this.serviceMoney = this.businessCharge[
          maxIndex
        ].shoufei;
        this.getOverMonth(
          this.businessCharge[maxIndex].taocan
        );
      }
    },
    showMonthSelect() {
      this.$monthselect({
        businessCharge: this.businessCharge,
        maxMonth: this.shebaoLength,
        callback: data => {
          this.shebaoSelectShow = data.lable;
          this.serviceMoney = data.shoufei;
          this.getOverMonth(data.overmonth);
        }
      });
    },
    /**
     * 获取结束月
     */
    getOverMonth(count) {
      this.param.sjgjOrderShebaoGongjijin.paymonth = count;
      let tempdata = this.param.sjgjOrderShebaoGongjijin.startmonth.split("-");
      tempdata[1] = Number(tempdata[1]) + Number(count) - 1;
      if (tempdata[1] > 12) {
        tempdata[1] = tempdata[1] - 12;
        tempdata[0]++;
      }
      tempdata[1] = (Array(2).join(0) + tempdata[1]).slice(-2);
      this.param.sjgjOrderShebaoGongjijin.overmonth = tempdata.join("-");
    },
    //获取城市配置
    getBaseInfo() {
      if (this.param.sjgjOrderShebaoGongjijin.cityid)
        this.$siaxios
          .get(
            `/order/getCityBase/${this.param.sjgjOrderShebaoGongjijin.cityid}`
          )
          .then(res => {
            this.baseInfos = res;
          });
    },
    /**
     * 展示顾问
     */
    showConsult() {
      if (this.userInfo.id == "") {
        this.$router.forward("/login/login");
        return;
      }
      this.$consult({ userid: this.userInfo.id });
    },
    /**
     * 获取可缴纳公积金月份
     */
    getSheBaoMonth() {
      //需要先认证
      if (this.userInfo.id && this.userInfo.renzhengstate != 1) {
        this.$confirm({
          describe: "办理业务请先认证，是否跳转认证页面",
          callback: boolean => {
            if (boolean) this.$router.forward("/mine/authentication");
          }
        });
        return;
      }
      this.$siaxios
        .get(`/order/getSheBaoMonth/${this.userInfo.id}`)
        .then(res => {
          if (!res || res.length <= 0) {
            this.$tips({
              title: "提示",
              describe:
                "因国家政策要求，只有在本单位缴纳社保的人员才能缴纳公积金。若想要缴纳公积金，请先下单社保缴纳业务。"
            });
            this.iniNoneUserData();
            return;
          }
          this.param.sjgjOrderShebaoGongjijin.startmonth = res[0];
          this.shebaoLength = res.length;
          this.isXujiao();
          this.getCity();
        });
    },
    getCity() {
      this.$siaxios
        .get(
          `/order/getSheBaoCity/${this.userInfo.id}/${this.param.sjgjOrderShebaoGongjijin.startmonth}`
        )
        .then(res => {
          this.param.sjgjOrderShebaoGongjijin.cityid = res.id;
          this.param.sjgjOrderShebaoGongjijin.city = res.regionName;
          this.iniSelect();
          this.getBaseInfo();
          this.getBasePrice();
        });
    },
    /**
     * 获取公积金基数
     */
    getBasePrice() {
      if (this.param.sjgjOrderShebaoGongjijin.cityid)
        this.$siaxios
          .get(
            `/order/getGongjijinbase/${this.userInfo.id}/${this.param.sjgjOrderShebaoGongjijin.cityid}?time=${this.param.sjgjOrderShebaoGongjijin.startmonth}`
          )
          .then(res => {
            this.param.sjgjOrderShebaoGongjijin.paybase = res;
          });
    },
    nextStemp() {
      if (this.userInfo.id == "") {
        this.$router.forward("/login/login");
        return;
      }
      if (this.param.sjgjOrderShebaoGongjijin.cityid == "") {
        this.$tips({ title: "提示", describe: "请选择缴纳城市" });
        return;
      }
      if (this.param.sjgjOrderShebaoGongjijin.paymonth == "") {
        this.$tips({ title: "提示", describe: "请填写开始缴纳月份" });
        return;
      }
      this.$siaxios
        .post(`/order/saveGongJiJin`, this.param, false)
        .then(res => {
          this.$router.forward({
            path: "/creatorder/orderdetail",
            query: { id: res.id }
          });
        });
    }
  }
};
</script>
