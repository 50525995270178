<template>
  <div class="updatetel-index" :class="{ 'bgwhite': type > 2 }">
    <div class="process">
      <div class="item pass">
        <div class="pro-num">1</div>
        <div class="title">验证原号码</div>
      </div>
      <div class="item" :class="{ 'pass': type > 1 }">
        <div class="pro-num">2</div>
        <div class="title">绑定手机号</div>
        <div class="line"></div>
      </div>
      <div class="item" :class="{ 'pass': type > 2 }">
        <div class="pro-num">3</div>
        <div class="title">修改成功</div>
        <div class="line"></div>
      </div>
    </div>
    <div class="update-from" v-if="type != 3">
      <div class="item">
        {{getTel()}}
        <span class="sendmsg" v-if="type == 1" @click="sendMsg(1)">{{timmer==61?'发送验证码':`${timmer}s后重新发送`}}</span>
      </div>
      <div class="item" v-if="type == 2">
        <input type="text" class="code" v-model="param.newTel" placeholder="请输入新手机号" />
        <span class="sendmsg" @click="sendMsg(2)">{{timmer==61?'发送验证码':`${timmer}s后重新发送`}}</span>
      </div>
      <div class="item">
        <input type="text" class="code" v-model="param.code" placeholder="请输入验证码" />
      </div>
      <div class="item" v-if="type == 2">
        <input
          type="password"
          v-model="param.password"
          class="code"
          autocomplete="new-password"
          placeholder="请输入密码"
        />
      </div>
    </div>
    <div class="submit-btn" v-if="type != 3" @click="nextStep()">下一步</div>
    <div class="up-success" v-else>
      <img class="upimg" src="@/assets/success.png" />
      <div class="title">修改成功</div>
      <div class="content">需要使用新的手机号码进行登录</div>
      <div class="up-btn" @click="$router.push('/login/login')">返回登录界面</div>
    </div>
  </div>
</template>

<script>
import "@/sass/views/mine/updatetel.scss";
import { mapGetters } from "vuex";
export default {
  name: "Updatetel",
  data() {
    return {
      loadingFullscreen: "",
      type: 1, //1 验证原号码 2 绑定手机号 3 修改成功
      timmer: 61,
      t1: "", //计时器
      param: {
        code: "", //验证码
        newTel: "", // 手机号
        oldTel: "", //旧手机号
        password: "" //密码
      }
    };
  },
  watch: {
    type() {
      window.clearInterval(this.t1);
      this.timmer = 61;
      this.param.code = "";
    }
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    this.param.oldTel = this.userInfo.tel;
  },
  methods: {
    /**
     * 发送验证码
     */
    sendMsg(type) {
      if (this.timmer != 61) return;
      let temptel = "";
      if (type == 1) {
        temptel = this.param.oldTel;
      } else {
        temptel = this.param.newTel;
      }
      var reg2 = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
      if (temptel == "") {
        this.$tips({ describe: "请输入手机号" });
        return;
      } else if (!reg2.test(temptel)) {
        this.$tips({ describe: "手机号码格式不正确" });
        this.registerData.tel = "";
        return;
      }
      this.timmer=60
      if (type == 1) {
        this.$siaxios.get("/smscode/" + temptel).then(() => {
          this.setCodeTimmer();
        });
      } else if (type == 2) {
        this.$siaxios
          .post("/login/verifytel", { newTel: temptel }, false)
          .then(() => {
            this.setCodeTimmer();
          });
      }
    },
    /**
     * 发送验证码倒计时
     */
    setCodeTimmer() {
      this.t1 = setInterval(() => {
        this.timmer--;
        if (this.timmer == 0) {
          window.clearInterval(this.t1);
          this.timmer = 60;
        }
      }, 1000);
    },
    /**
     * 手机号脱敏
     */
    getTel() {
      var pat = /(\d{3})\d*(\d{4})/;
      return this.userInfo.tel.replace(pat, "$1****$2");
    },
    /**
     * 下一步
     */
    nextStep() {
      if (this.type == 1) {
        if (this.param.code == "") {
          this.$tips({
            title: "提示",
            describe: "请输入验证码"
          });
          return;
        }
        this.$siaxios
          .get(
            `/login/checkTel?tel=${this.param.oldTel}&code=${this.param.code}`
          )
          .then(() => {
            this.type++;
            this.param.code = "";
          });
      } else if (this.type == 2) {
        if (this.param.newTel == "") {
          this.$tips({
            title: "提示",
            describe: "请输入新手机号"
          });
          return;
        }
        if (this.param.code == "") {
          this.$tips({
            title: "提示",
            describe: "请输入验证码"
          });
          return;
        }
        if (this.param.password == "") {
          this.$tips({
            title: "提示",
            describe: "请输入密码"
          });
          return;
        }

        this.$siaxios.post("/login/bindTel", this.param, false).then(() => {
          this.type++;
          this.param.code = "";
        });
      }
    }
  }
};
</script>