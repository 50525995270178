import { render, staticRenderFns } from "./agreement.vue?vue&type=template&id=566acc4d&scoped=true&"
var script = {}
import style0 from "./agreement.vue?vue&type=style&index=0&id=566acc4d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "566acc4d",
  null
  
)

export default component.exports