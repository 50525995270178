<template>
  <div>
    <div class="yiliaobx">
      <img src="@/assets/views/gallery/Medical/head.png" alt="">
      <div class="Written"><span>——</span> 医疗保险 <span>——</span></div>
      <div class="overlay"></div>
      <div class="ContBox">
        <div class="title">
          <div>
            <img src="@/assets/views/gallery/Medical/Ped1.png" alt="">
            <div>单位和个人缴费比例</div>
          </div>
        </div>
        <ul class="Proportion">
          <li>
            <div>2%</div>
            <div class="heng"></div>
            <div>个人缴费</div>
          </li>
          <li>
            <div>9.5%</div>
            <div class="heng"></div>
            <div>单位缴费</div>
          </li>
        </ul>
        <div class="interval"></div>
        <div class="title1">
          <div>
            <img src="@/assets/views/gallery/Medical/Ped2.png" alt="">
            <div>报销上限</div>
          </div>
        </div>
        <div class="limit">
          <ul>
            <li>门诊最高报销</li>
            <li>1600元</li>
          </ul>
          <ul>
            <li>住院最高报销</li>
            <li>80万</li>
          </ul>
        </div>
        <div class="title2">
          <div>
            <img src="@/assets/views/gallery/Medical/Ped3.png" alt="">
            <div>门诊报销比例</div>
          </div>
        </div>
        <div class="currency">
          <ul class="one">报销标准</ul>
          <div>
            <ul class="center">
              <li>200元</li>
              <li>报销起付线</li>
            </ul>
            <ul class="center">
              <li>1600</li>
              <li>报销上限</li>
            </ul>
          </div>
        </div>
        <div class="currency">
          <ul class="one1">报销比例</ul>
          <div>
            <ul class="center1">
              <li>86%</li>
              <li>政策报销比例</li>
            </ul>
            <ul class="center1">
              <li>50%</li>
              <li>预计报销比例</li>
            </ul>
          </div>
        </div>
        <table>
          <tr>
            <th>医院类别</th>
            <th>起付标准</th>
            <th>报销比例</th>
          </tr>
          <tr>
            <td>一级医院</td>
            <td class="standard">200元</td>
            <td class="Propor">90%</td>
          </tr>
          <tr>
            <td>二级医院</td>
            <td class="standard">500元</td>
            <td class="Propor">88%</td>
          </tr>
          <tr>
            <td>三级医院</td>
            <td class="standard">800元</td>
            <td class="Propor">86%</td>
          </tr>
          <tr>
            <td>社区医院</td>
            <td class="standard">200元</td>
            <td class="Propor">60%</td>
          </tr>
        </table>
        <div class="title3">
          <div>
            <img src="@/assets/views/gallery/Medical/Ped4.png" alt="">
            <div>住院报销比例</div>
          </div>
        </div>
        <div class="currency">
          <ul class="one2">报销标准</ul>
          <div>
            <ul class="center2">
              <li>200元</li>
              <li>报销起付线</li>
            </ul>
            <ul class="center2">
              <li>80万元</li>
              <li>报销上限</li>
            </ul>
          </div>
        </div>
        <div class="currency">
          <ul class="one3">报销比例</ul>
          <div>
            <ul class="center3">
              <li>90%</li>
              <li>政策报销比例</li>
            </ul>
            <ul class="center3">
              <li>70%</li>
              <li>预计报销比例</li>
            </ul>
          </div>
        </div>
        <div class="addtable">
          <div class="addtop">
            <div class="addtop1">类别</div>
            <div class="addtop2">
              <div>医院</div>
              <div>类别</div>
            </div>
            <div class="addtop2">
              <div>起付</div>
              <div>标准</div>
            </div>
            <div class="addtop3">
              <div class="addtop4top">报销比例</div>
              <div class="addtop4bottom1">4万以下</div>
              <div class="addtop4bottom2">4万以下</div>
            </div>
            <div class="addtop4">
              <div>支付</div>
              <div>限额</div>
            </div>
          </div>
          <div class="addbottom">
            <div class="addbottom1">
              <ul>
                <li>基本</li>
                <li>医疗</li>
              </ul>
              <ul>
                <li>住</li>
                <li>院</li>
              </ul>
            </div>
            <div class="addbottom2">
              <div class="smallfk">一级</div>
              <div class="smallfk">二级</div>
              <div class="smallfk1">三级</div>
            </div>
            <div class="addbottom2">
              <div class="smallfk">200元</div>
              <div class="smallfk">500元</div>
              <div class="smallfk1">800元</div>
            </div>
            <div class="addbottom3">
              <div class="smallfk">90%</div>
              <div class="smallfk">88%</div>
              <div class="smallfk1">86%</div>
            </div>
            <div class="addbottom4">95%</div>
            <div class="addbottom5">20万</div>
          </div>
          <div class="addcenter">
            <div class="addcenter1">
              <div>大病医疗</div>
              <div>救助基金</div>
            </div>
            <div class="addcenter2">
              <div class="addcenter2top">超额补助</div>
              <div class="addcenter2bottom">大额补助</div>
            </div>
            <div class="addcenter3">
              <div class="addcenter3top">20万</div>
              <div class="addcenter3bottom">15000</div>
            </div>
            <div class="addcenter4">
              <div class="addcenter4top">90%</div>
              <div class="addcenter4bottom">75%</div>
            </div>
            <div class="addcenter5">
              <div class="addcenter5top">40万</div>
              <div class="addcenter5bottom">20万</div>
            </div>
          </div>
        </div>
        <div class="interval"></div>
        <div class="title4">
          <div>
            <img src="@/assets/views/gallery/Medical/Ped5.png" alt="">
            <div>青岛医疗保险报销材料</div>
          </div>
        </div>
        <ul class="specific">
          <li>身份证或社会保障卡的原件</li>
          <li>定点医疗机构三级或二级医院的专科医生开具的疾病诊断证明书原件</li>
          <li>门诊病历、检查、检验结果报告单等就医资料原件</li>
          <li>财政、税务统一医疗机构门诊收费收据原件</li>
          <li>医院电脑打印的门诊费用明细清单或医生开具处方的付方原件</li>
          <li>定点药店：税务商品销售统一发票及电脑打印清单原件</li>
          <li>如代办则提供代办人身份证原件</li>
        </ul>
      </div>
      
    </div>
    <!-- 浮动 -->
    <common :url="url" ref="common" state="2" type="1"></common>
  </div>
</template>

<script>
import "@/sass/views/gallery/yiliaobx.scss"
import common from "@/views/questions/common/CommonPolicy"
export default {
  components:{
    common
  },
  data(){
    return {
      url:'/gallery/yiliaoPension'
    }
  },
  beforeRouteEnter(to,from,next){
    next( _this =>{
      if(from.path == _this.url){
        _this.$refs.common.changeToLink()
      }
    }
    )
  },
  methods:{}
}
</script>

<style>

</style>