<template>
  <div>
    <div class="shebaoka">
      <!-- <div class="head"> -->
        <img src="@/assets/views/gallery/Card/head.png" alt="">
        <div class="Written"><span>——</span> 社保卡 <span>——</span></div>
        <div class="overlay"></div>
      <!-- </div> -->
      <div class="ContBox">
        <div class="title">
          <div>
            <img src="@/assets/views/gallery/Card/Ped1.png" alt="">
            <div>申领社保卡流程</div>
          </div>
        </div>
        <ul class="process">
          <li>
            <img src="@/assets/views/gallery/Card/BZ1.png" alt="">
            <div>参保登记</div>
          </li>
          <div class="aaa">-------></div>
          <div class="bbb">-------></div>
          <li>
            <img src="@/assets/views/gallery/Card/BZ2.png" alt="">
            <div>合作银行申办社保卡</div>
          </li>
          <li>
            <img src="@/assets/views/gallery/Card/BZ3.png" alt="">
            <div>领取社保卡</div>
          </li>
          
        </ul>
        <div class="interval"></div>
        <div class="title1">
          <div>
            <img src="@/assets/views/gallery/Card/Ped2.png" alt="">
            <div>社会保障卡申办时限</div>
          </div>
        </div>
        <div class="Modular">
          <div></div>
          <span>首次申领社保卡</span>
        </div>
        <ul class="specific">
          <li>
            办卡时间：<span>7</span>个工作日
          </li>
          <li>参保人员首次申领社保卡，只要符合社保卡制卡条件的，合作银行营业网点都应当自受理申请之日起，7个工作日内向申领人发卡</li>
        </ul>
        <div class="Modular">
          <div></div>
          <span>挂失补还卡</span>
        </div>
        <ul class="specific">
          <li>
            办卡时间：<span>7</span>个工作日
          </li>
          <li>参保人员挂失补换壮保卡，只要符合社保卡制卡条件的，合作银行营业网点都应当自受理申请之日起，7个工作日内向申领人发卡</li>
        </ul>
        <div class="Modular">
          <div></div>
          <span>批量申办社保卡</span>
        </div>
        <ol class="specific">
          <li>
            办卡时间：<span>可适当延长</span>
          </li>
          <li>参保人员挂失补换壮保卡，只要符合社保卡制卡条件的，合作银行营业网点都应当自受理申请之日起，7个工作日内向申领人发卡</li>
        </ol>
        <div class="interval"></div>
        <div class="title2">
          <div>
            <img src="@/assets/views/gallery/Card/Ped3.png" alt="">
            <div>社保卡就医一卡通</div>
          </div>
        </div>
        <ul class="guide1">
          <li>
            <img src="@/assets/views/gallery/Card/Smbz1.png" alt="">
            <div>挂号</div>
          </li>
          <li>
            <img src="@/assets/views/gallery/Card/Smbz2.png" alt="">
            <div>就医</div>
          </li>
        </ul>
        <ul class="guide2">
          <li>
            <img src="@/assets/views/gallery/Card/Smbz3.png" alt="">
            <div>结算</div>
          </li>
          <li>
            <img src="@/assets/views/gallery/Card/Smbz4.png" alt="">
            <div>取药</div>
          </li>
        </ul>
        <div class="title3">
          <div>
            <img src="@/assets/views/gallery/Card/Ped4.png" alt="">
            <div>办理条件</div>
          </div>
        </div>
        <ul class="specificth">
          <div></div>
          无论年龄、无论国籍、无论户籍，只要您在青岛参加社会保险或在青岛享受社保待遇，均可携带有效证件就近办理，任意合作银行，任意网店都可以！
        </ul>
        <div class="interval"></div>
        <div class="title4">
          <div>
            <img src="@/assets/views/gallery/Card/Ped5.png" alt="">
            <div>办理材料</div>
          </div>
        </div>
        <ul class="specific1">
          <li>成年人仅需携带本人有效身份证件</li>
          <li>学生儿童须由监护人代办，根据人民银行规定，代办时需携带双发有效身份证件和关系证明材料，关系证明材料包括能体现监护人与被监护人关系的户口簿和医学出生证明等材料</li>
        </ul>
      </div>
    </div>
    <!-- 浮动 -->
    <common :url="url" ref="common" state="6" type="1"></common>
  </div>
</template>

<script>
import "@/sass/views/gallery/shebaoka.scss"
import common from "@/views/questions/common/CommonPolicy"
export default {
  components:{
    common
  },
  data(){
    return{
      url:'/gallery/shebaokaPension',
    }
  },
  beforeRouteEnter(to,from,next){
    next( _this =>{
      if(from.path == _this.url){
       _this.$refs.common.changeToLink()
      }
    }
    )
  },
  methods:{}
}
</script>