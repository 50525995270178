<template>
  <div>
    <div class="shiyebx">
      <img src="@/assets/views/gallery/BuyCar/head.png" alt="">
      <div class="Written"><span>——</span> 失业保险 <span>——</span></div>
      <div class="overlay"></div>
      <div class="ContBox">
        <div class="title">
          <div>
            <img src="@/assets/views/gallery/BuyCar/Ped1.png" alt="">
            <div>申领社保卡流程</div>
          </div>
        </div>
        <ul class="Proportion">
          <li>
            <div>0.5%</div>
            <div class="heng"></div>
            <div>个人缴费（非农）</div>
          </li>
          <li>
            <div>1%</div>
            <div class="heng"></div>
            <div>单位缴费</div>
          </li>
        </ul>
        <div class="interval"></div>
        <div class="title1">
          <div>
            <img src="@/assets/views/gallery/BuyCar/Ped2.png" alt="">
            <div>失业保险领取条件</div>
          </div>
        </div>
        <ul class="specific">
          <li>按照规定提交失业保险，所在单位和本人已按照规定履行缴费义务满一年的</li>
          <li>非因本人意愿中断就业的</li>
          <li>已依法定程序办理失业登记的</li>
          <li>有求职要求，愿意接受职业培训、职业介绍的</li>
        </ul>
        <div class="interval"></div>
        <div class="title2">
          <div>
            <img src="@/assets/views/gallery/BuyCar/Ped3.png" alt="">
            <div>领取期限(单位:个月)</div>
          </div>
        </div>
        <div id="againTable"></div>
        <div class="Last">领取标准(单位:元)：<span>1000元</span></div>
      </div>
    </div>
    <!-- 浮动 -->
    <common :url="url" ref="common" state="5" type="1"></common>
  </div>
</template>

<script>
import "@/sass/views/gallery/shiyebx.scss"
import common from "@/views/questions/common/CommonPolicy"
export default {
  components:{
    common
  },
  data(){
    return{
      url:'/gallery/shiyePension',
    }
  },
  beforeRouteEnter(to,from,next){
    next( _this =>{
      if(from.path == _this.url){
        _this.$refs.common.changeToLink()
      }
    }
    )
  },
  
  methods:{
    drawLine1(){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("againTable"));
      // 绘制图表
      myChart.setOption({
        backgroundColor: "#f5f5f5",
        color: ["#4162ff"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          //图表的位置
          top: "30%",
          left: "5%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        yAxis: [
          {
            type: 'value',
            // data: ["0","6","12","18","24"],
            max:24,
            min:0,
            scale : true,
            splitNumber : 4,
            name: "月",
            nameTextStyle: {
              //---坐标轴名称样式
              color: "#999999",
              padding: [5, 0, 0, -23], //---坐标轴名称相对位置
              fontSize:"100%",
            },
            splitLine: { show: false },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#cccccc"
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize:"100%"
              }
            }
          }
        ],
        xAxis: [
          {
            type: "category",
            data: ["1<年<5", "5≤年<10", "10年以上"],
            nameGap: 15,
            splitLine: { show: false },
            name: "级别",
            nameTextStyle: {
              //---坐标轴名称样式
              color: "#999999",
              padding: [30, 0, 0, -30], //---坐标轴名称相对位置
              fontSize:"100%",
            },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#cccccc"
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize:"100%"
              },
              interval: 0, //代表显示所有x轴标签显示
            }
          }
        ],

        nameTextStyle: {
          // 坐标轴名称样式
          color: "red",
          padding: [5, 0, 0, -5]
        },
        series: [
          {
            name: "级别",
            type: "bar",
            stack: "总量",
            barWidth: "30px",
            label: {
              show:true,
              position:'top',
              fontSize:"100%"
            },
            data: [12, 18, 24]
          },
        ]
      });
      setTimeout(function (){
        window.onresize = function () {
            myChart.resize();
          }
      },200)
    },
  },
  mounted(){
    this.drawLine1();
  }
}
</script>

<style>

</style>