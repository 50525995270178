<template>
  <div>
    <div class="gjjfuwu">
      <div class="Img">
        <img src="@/assets/views/gallery/Accumulation/head1.jpg" alt />
        <div class="Img1">公积金一键查询、办理</div>
        <div class="Img2">方便快捷，简单易操作</div>
        <div class="Img3"></div>
      </div>
      <ul class="three">
        <li @click="goExchange">
          <img src="@/assets/views/gallery/Accumulation/Ped1.png" alt />
          <div>公积金查询</div>
        </li>
        <li @click="$router.forward({path:'/classify/list',query: { id: 1}})">
          <img src="@/assets/views/gallery/Accumulation/Ped2.png" alt />
          <div>公积金服务</div>
        </li>
        <li @click="$router.forward({path:'/accumulationFund/list'})">
          <img src="@/assets/views/gallery/Accumulation/Ped3.png" alt />
          <div>公积金问问</div>
        </li>
      </ul>
      <div class="Identification">
        <div></div>
        <div>服务内容</div>
      </div>
      <div class="cente">
        <img src="@/assets/views/gallery/Accumulation/center.png" alt />
        <div class="cente1">公积金代缴</div>
        <div class="cente2">打破就业限制，自选基数与比例</div>
      </div>
      <ul class="table">
        <li
          class="content"
          @click="$router.forward({path:'/creatorder/accumulationfund',query:{type:1}})"
        >
          <div>
            <img src="@/assets/views/gallery/Accumulation/Ped4.png" alt />
          </div>
          <ul>
            <li>公积金代缴</li>
            <li>代缴费用&nbsp;按月支付</li>
          </ul>
        </li>
        <li class="content" @click="$router.forward({path:'/creatorder/general',query:{id:14,businessname:'公积金补缴'}})">
          <div>
            <img src="@/assets/views/gallery/Accumulation/Ped5.png" alt />
          </div>
          <ul>
            <li>公积金补缴</li>
            <li>可实时查询</li>
          </ul>
        </li>
        <li class="content" @click="$router.forward({path:'/creatorder/general',query:{id:15,businessname:'公积金贷款'}})">
          <div>
            <img src="@/assets/views/gallery/Accumulation/Ped6.png" alt />
          </div>
          <ul>
            <li>公积金贷款</li>
            <li>快速办理&nbsp;省心</li>
          </ul>
        </li>
        <li class="content" @click="$router.forward({path:'/creatorder/general',query:{id:16,businessname:'公积金转入'}})">
          <div>
            <img src="@/assets/views/gallery/Accumulation/Ped7.png" alt />
          </div>
          <ul>
            <li>公积金转入</li>
            <li>在线办理、更便捷</li>
          </ul>
        </li>
        <li class="content" @click="$router.forward({path:'/creatorder/general',query:{id:17,businessname:'公积金转出'}})">
          <div>
            <img src="@/assets/views/gallery/Accumulation/Ped8.png" alt />
          </div>
          <ul>
            <li>公积金转出</li>
            <li>属地化转出、更放心</li>
          </ul>
        </li>
        <li class="content" @click="$router.forward({path:'/creatorder/general',query:{id:18,businessname:'公积金提取'}})">
          <div>
            <img src="@/assets/views/gallery/Accumulation/Ped9.png" alt />
          </div>
          <ul>
            <li>公积金提取</li>
            <li>快速到账&nbsp;无风险</li>
          </ul>
        </li>
      </ul>
      <div class="interval"></div>
      <div class="Identification1">
        <div></div>
        <div>缴纳优势</div>
      </div>
      <div class="jnys">
        <img src="@/assets/views/gallery/Accumulation/advantage.png" alt />
        <div class="jnysyy"></div>
        <div class="jnys1">公积金缴纳&nbsp;轻享六大好处</div>
        <div
          class="jnys2"
        >买房&nbsp;|&nbsp;职工个人收入&nbsp;|&nbsp;儿女购房&nbsp;|&nbsp;税收优惠&nbsp;|&nbsp;住房消费提取&nbsp;|</div>
        <div class="jnys3">非住房消费提取</div>
        <div class="jnys4"></div>
        <div class="jnys5" @click="$router.forward('/gallery/advantage')">查看详情</div>
      </div>
      <div class="Identification">
        <div></div>
        <div>服务优势</div>
      </div>
      <div class="Bot">
        <ul class="table1">
          <li class="content1">
            <div>
              <img src="@/assets/views/gallery/Accumulation/Bed1.png" alt />
            </div>
            <ul>
              <li>动态随时查</li>
              <li>参保随时查，动态全透明</li>
            </ul>
          </li>
          <li class="content1">
            <div>
              <img src="@/assets/views/gallery/Accumulation/Bed2.png" alt />
            </div>
            <ul>
              <li>安全更可靠</li>
              <li>不断缴，不漏缴，零风险</li>
            </ul>
          </li>
          <li class="content1">
            <div>
              <img src="@/assets/views/gallery/Accumulation/Bed3.png" alt />
            </div>
            <ul>
              <li>公积金随时缴</li>
              <li>随时随地&nbsp;操作更自由</li>
            </ul>
          </li>
          <li class="content1">
            <div>
              <img src="@/assets/views/gallery/Accumulation/Bed4.png" alt />
            </div>
            <ul>
              <li>缴纳速度快</li>
              <li>微信扫一扫，三分钟缴纳</li>
            </ul>
          </li>
          <li class="content1">
            <div>
              <img src="@/assets/views/gallery/Accumulation/Bed5.png" alt />
            </div>
            <ul>
              <li>服务覆盖广</li>
              <li>多个城市开通业务</li>
            </ul>
          </li>
          <li class="content1">
            <div>
              <img src="@/assets/views/gallery/Accumulation/Bed6.png" alt />
            </div>
            <ul>
              <li>答疑更专业</li>
              <li>人社专家&nbsp;资深答疑团队</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import "@/sass/views/gallery/GJJfuwu.scss";
import { mapGetters } from "vuex";
export default {
  name: "hello",
  data() {
    return {
      msg: "Welcome to Your Vue.js App"
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  mounted() {
    this.getBaseInfo();
  },
  methods: {
    /**
     * 跳转外部链接
     */
    goExchange() {
            if (this.$isPlus) {
                /* eslint-disable */
                uni.navigateTo({
                    url: '/pages/index/otherLike?link='+this.baseInfosURL
                });
            }else{
                window.location.href = this.baseInfosURL;
            }
            
        },
    /**
     * 设置公积金外链
     */
    getBaseInfo() {
      this.baseInfosURL = "http://12333.qingdao.gov.cn/grcx";
      if(!this.userInfo.cityid) return
      this.$siaxios
        .get(`/order/getCityBase/${this.userInfo.cityid}`)
        .then(res => {
          if (res) this.baseInfosURL = res.gongjijinurl;
        });
    }
  }
};
</script>

<style>
</style>