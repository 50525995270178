<template>
  <div class="forget">
    <div class="title">
      <p class="top">忘记密码</p>
    </div>
    <div class="from-con">
      <div class="input-con">
        <input
          class="in-phone"
          type="number"
          maxlength="11"
          onkeyup="this.value=this.value.replace(/\D/g,'')"
          onafterpaste="this.value=this.value.replace(/\D/g,'')"
          placeholder="手机号码"
          v-model="param.tel"
        />
      </div>
      <div class="input-con">
        <input
          class="in-phone phone-code-width"
          onkeyup="this.value=this.value.replace(/\D/g,'')"
          onafterpaste="this.value=this.value.replace(/\D/g,'')"
          type="text"
          maxlength="6"
          placeholder="短信验证码"
          v-model="param.code"
        />
        <div class="sendmsg-btn" @click="getcode()">{{count==60?'获取验证码':`${count}s后重新发送`}}</div>
      </div>
      <div class="input-con">
        <input class="in-phone" type="password" placeholder="设置登录密码" v-model="param.password" />
      </div>
      <div class="input-con">
        <input class="in-phone" type="password" placeholder="确认密码" v-model="password2" />
      </div>
    </div>
    <div class="submit-info" @click="affirm()">确定</div>
  </div>
</template>
<script>
import "@/sass/views/login/forget.scss";
export default {
  data() {
    return {
      param: {
        tel: "",
        password: "",
        code: ""
      },
      password2: "",
      count: 60,
    };
  },
  methods: {
    /**
     * 获取验证码
     */
    getcode() {
      if (this.count != 60) return;
      let reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
      if (this.param.tel == "") {
        this.$tips({ describe: "请输入手机号" });
      } else if (!reg.test(this.param.tel)) {
        this.$tips({ describe: "手机号码格式不正确" });
      } else {
        this.message = 60;
        this.$axios.get("/smscode/" + this.param.tel).then(() => {
          this.timer();
        });
      }
    },
    timer() {
        this.count--;
      if (this.count > 0) {
        setTimeout(this.timer, 1000);
      } else {
        this.count = 60;
      }
    },
    affirm() {
      let reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
      if (this.param.tel == "") {
        this.$tips({ describe: "请输入手机号" });
        return;
      } else if (!reg.test(this.param.tel)) {
        this.$tips({ describe: "手机号码格式不正确" });
        return;
      }
      //验证码
      if (this.param.code == "") {
        this.$tips({ describe: "验证码不能为空" });
        return;
      }
      //密码
      if (this.param.password == "") {
        this.$tips({ describe: "密码不能为空" });
        return;
      }
      let pattern = /^[\w_-]{6,10}$/;
      if (!pattern.test(this.param.password)) {
        this.$tips({
          describe: "请输入6到10位的大小写字母、数字、下划线和减号"
        });
        return;
      }
      //二次密码
      console.log(this.param.password + "   " + this.password2);
      console.log(this.param.password != this.password2);
      if (this.param.password != this.password2) {
        this.$tips({ describe: "两次输入密码不一致" });
        return;
      }
      this.$siaxios.post("/login/changePassword", this.param).then(() => {
        this.$router.forward("/login/login");
      });
    }
  }
};
</script>