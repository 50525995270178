<template>
  <div class="authentication-index">
    <!-- step1 -->
    <div v-show="isShow">
      <div class="item">
        <div class="lable">账号</div>
        <span class="showinfo">{{param.tel}}</span>
      </div>
      <div class="item">
        <div class="lable">姓名</div>
        <input
          type="text"
          v-if="userInfo.renzhengstate != 1"
          v-model="param.name"
          placeholder="请输入姓名"
        />
        <span v-else class="showinfo">{{param.name}}</span>
      </div>
      <div class="item">
        <div class="lable">身份证</div>
        <input
          type="text"
          v-if="userInfo.renzhengstate != 1"
          v-model="param.idcard"
          placeholder="请输入身份证"
        />
        <span v-else class="showinfo">{{param.idcard}}</span>
      </div>
      <!-- <div class="item">
        <div class="lable">身份证照片</div>
        <div class="idcard-con">
          <div class="pull-left" @click="choiseUploadFile">
            <img v-if="param.frontImg" :src="param.frontImg" alt class="idcard-img" />
            <img v-else src="@/assets/views/mine/front.png" alt class="idcard-img" />
            <input type="file" @change="uploadFront" style="display: none" />
          </div>
          <div class="pull-right" @click="choiseUploadFile">
            <img v-if="param.contraryImg" :src="param.contraryImg" alt class="idcard-img" />
            <img v-else src="@/assets/views/mine/verso.png" alt class="idcard-img" />
            <input type="file" @change="uploadBack" style="display: none" />
          </div>
        </div>
      </div> -->
      <div class="tips">
        <div class="title">温馨提示</div>
        <div class="content">基础信息填写后将不允许再次修改，如填写信息有误，请联系顾问进行修改</div>
      </div>
      <!-- <div class="submit-btn" @click="nextStep()">{{(userInfo.renzhengstate != 1)?'下一步':'展示认证签名'}}</div> -->
      <div class="submit-btn" @click="commit()">确认</div>
    </div>
    <!-- step2 -->
    <div v-show="!isShow">
      <div class="autograph">
        <div class="signTips">请在下面方框内手写签名</div>
        <section class="signature">
          <div class="signatureBox">
            <div class="canvasBox" ref="canvasHW" id="canvasHW">
              <img
                v-if="param.signatureImg && !ifSign"
                class="imgCanvas"
                :src="param.signatureImg"
                ref="img"
              />
              <canvas
                v-show="userInfo.renzhengstate != 1 && ifSign"
                ref="canvasF"
                class="canvasX"
                @touchstart="touchStart"
                @touchmove="touchMove"
                @touchend="touchEnd"
              ></canvas>
              <div class="btnBox">
                <!-- <div @click="commit">提交签名</div> -->
                <div @click="overwrite" class="overwrite" v-if="userInfo.renzhengstate != 1">
                  <img src="@/assets/views/mine/overwrite.png" alt />
                  <span>重写</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="signWarning">请保证字体工整，文字清晰</div>
        <div class="signBth">
          <template v-if="userInfo.renzhengstate != 1">
            <div class="step1" @click="isShow = true">上一步</div>
            <div class="step1 submit ml40" @click="commit()">立即提交</div>
          </template>
          <div class="step1 submit" @click="isShow = true" v-else>返回</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/sass/views/mine/authentication.scss";
import { mapGetters } from "vuex";
export default {
  name: "Authentication",
  data() {
    return {
      loadingFullscreen: "",
      isShow: true,
      param: {
        name: "", //姓名
        tel: "", // 账号
        idcard: "", //身份证号
        front: "", //身份证正面id
        contrary: "", //身份证反面id
        frontImg: "",
        contraryImg: "",
        signature: ""
      },
      // 签名
      stageInfo: "",
      imgUrl: "",
      client: {},
      points: [],
      canvasTxt: null,
      startX: 0,
      startY: 0,
      moveY: 0,
      moveX: 0,
      endY: 0,
      endX: 0,
      w: null,
      h: null,
      file2: "",
      ifSign: true
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    }),
    isAllShow() {
      return {
        isShow: this.isShow,
        ifSign: this.ifSign
      };
    }
  },
  watch: {
    isAllShow: {
      deep: true,
      handler(val) {
        if (!val.isShow || val.ifSign) {
          this.$nextTick(() => {
            let canvas = this.$refs.canvasF;
            this.canvasTxt = canvas.getContext("2d");
            canvas.width = this.$refs.canvasHW.offsetWidth;
            canvas.height = this.$refs.canvasHW.offsetHeight - 30;
            this.canvasTxt.fillStyle = "#fff";
            // 在canvas绘制前填充白色背景
            this.canvasTxt.fillRect(0, 0, canvas.width, canvas.height);
            this.stageInfo = canvas.getBoundingClientRect();
          });
        }
      }
    }
  },
  mounted() {
    this.param.tel = this.userInfo.tel;
    this.getUserinfo();
  },
  methods: {
    nextStep() {
      if (!this.param.name) {
        this.$tips({ describe: "请输入您的姓名" });
        return;
      }
      if (!this.param.idcard) {
        this.$tips({ describe: "请输入您的身份证" });
        return;
      }
      //身份证验证正则
      var idcardReg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (!idcardReg.test(this.param.idcard)) {
        this.$tips({ describe: "请输入正确的身份证号" });
        return;
      }
    //   if (!this.param.front || !this.param.contrary) {
    //     this.$tips({ describe: "请上传身份证正反面" });
    //     return;
    //   }
      this.isShow = false;
    },
    touchStart(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientX - this.stageInfo.left,
          y: ev.targetTouches[0].clientY - this.stageInfo.top
        };
        this.startX = obj.x;
        this.startY = obj.y;
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.points.push(obj);
      }
    },
    touchMove(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientX - this.stageInfo.left,
          y: ev.targetTouches[0].clientY - this.stageInfo.top
        };
        this.moveY = obj.y;
        this.moveX = obj.x;
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.startY = obj.y;
        this.startX = obj.x;
        this.points.push(obj);
      }
    },
    touchEnd(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientX - this.stageInfo.left,
          y: ev.targetTouches[0].clientY - this.stageInfo.top
        };
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.points.push(obj);
      }
    },
    //重写
    overwrite() {
      this.ifSign = true;
      this.canvasTxt.clearRect(
        0,
        0,
        this.$refs.canvasF.width,
        this.$refs.canvasF.height
      );
      this.points = [];
    },
    //提交签名
    commit() {
      // 提交签名
      // this.imgUrl = this.$refs.canvasF.toDataURL();
      this.$refs.canvasF.toBlob(blob => {
        //以时间戳作为文件名 实时区分不同文件 按需求自己定义就好
        let filename = `${this.param.name}.jpg`;
        //转换canvas图片数据格式为formData
        this.file2 = new File([blob], filename, { type: "image/jpg" });
        const formData = new FormData();
        formData.append("file", this.file2);
        //签名上传
        this.$siaxios
          .post("/uploadFile/uploadFile/7", formData, false)
          .then(res => {
            this.param.signature = res.fileid;
            // 提交
            this.submit();
          });
      });
    },
    submit() {
    //   if (!this.param.signature) {
    //     this.$tips({ describe: "请上传手写签名" });
    //     return;
    //   }
      this.$siaxios.post("/login/realNameAuth", this.param, false).then(() => {
        let tempUserInfo = JSON.parse(JSON.stringify(this.userInfo));
        tempUserInfo.name = this.param.name;
        tempUserInfo.idcard = this.param.idcard;
        tempUserInfo.renzhengstate = 1;
        this.$store.commit("setMyUserInfo", tempUserInfo);
        this.$router.back();
      });
    },
    getUserinfo() {
      this.$siaxios
        .get(`/login/realNameAuthInfo/${this.userInfo.id}`)
        .then(res => {
          this.param = res;
          if (this.param.signatureImg) {
            this.ifSign = false;
          }
          this.$forceUpdate();
        });
    },
    /**
     * 选中上传图片
     */
    choiseUploadFile(event) {
      if (this.userInfo.renzhengstate == 1) return;
      let myTaget = event.currentTarget;
      let myfild = myTaget.getElementsByTagName("input");
      myfild[0].click();
    },
    uploadFront(event) {
      this.uploadFile(event, 1);
    },
    uploadBack(event) {
      this.uploadFile(event, 2);
    },
    uploadFile(event, type) {
      let _this = this;
      let myTaget = event.currentTarget;
      let myfild = myTaget.files[0];
      if (!this.isImg(myfild.type)) {
        this.$tips({
          title: "提示",
          describe: "只能上传图片格式"
        });
        event.currentTarget.value = "";
        return;
      }
      // 压缩
      if (myfild.size <= 1 * 1024 * 1024) {
        //判断图片是否小于1M,是就直接上传
        this.postImg(myTaget, myfild, type);
      } else {
        // 压缩图片
        let reader = new FileReader();
        reader.readAsDataURL(myfild);
        reader.onloadend = function() {
          let img = new Image();
          img.src = this.result;
          let canvas = document.createElement("canvas");
          let drawer = canvas.getContext("2d");
          img.onload = function() {
            let width = img.width;
            let height = img.height;
            if ((width * height) / 1000000 > 1) {
              width = 720;
              height = width * (img.height / img.width);
            }
            canvas.width = width;
            canvas.height = height;
            drawer.drawImage(img, 0, 0, canvas.width, canvas.height);
            // img.src = canvas.toDataURL("image/jpg", 0.7); //0.7图片质量
            canvas.toBlob(blob => {
              myfild = new File([blob], `${myfild.name.substring(0, 10)}.jpg`);
              _this.postImg(myTaget, myfild, type);
            });
          };
        };
      }
    },
    /**
     * 验证是否是图片
     * @param {*} type  图片类型
     */
    isImg(type) {
      const isJPG = type === "image/jpeg";
      const isPNG = type === "image/png";
      const isBMP = type === "image/bmp";
      const isGIF = type === "image/gif";
      const isWEBP = type === "image/webp";
      const isPic = isJPG || isPNG || isBMP || isGIF || isWEBP;
      return isPic;
    },
    postImg(event, myfild, type) {
      const formData = new FormData();
      formData.append("file", myfild);
      this.$siaxios
        .post("/uploadFile/uploadFile/3", formData, false)
        .then(res => {
          if (type == 1) {
            this.param.front = res.fileid;
            this.param.frontImg = res.fileurl;
          } else {
            this.param.contrary = res.fileid;
            this.param.contraryImg = res.fileurl;
          }
        });
      event.value = "";
    }
  }
};
</script>
