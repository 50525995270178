<template>
    <div class="service-index" :style="{paddingTop: $statusBarHeight + 'px' }">
        <div class="infos">
            <div class="portrait">
                <img :src="userInfo.salemenheadurl" alt="">
            </div>
            <div class="title">您好,我是您的专属顾问</div>
            <div class="name">{{userInfo.salemennickname}}</div>
        </div>
        <div class="show-mian">
            <img :src="userInfo.salemencodeurl" alt class="qrcode" />
            <div class="tips">长按添加顾问微信，沟通更便捷</div>
        </div>
        <div class="consult-submit" @click="goService()">转至在线客服</div>
    </div>
</template>

<script>
import "@/sass/views/mine/service.scss";
import { mapGetters } from "vuex";

export default {
    name: "Service",
    data() {
        return {
            serviceUrl: ""
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo"
        })
    },
    methods: {
        goService() {
            /* eslint-disable */
            _53App.getApp('chat').openSdkUrl('float');
        }
    }
};
</script>
<style lang="scss" scoped>
#mobile_minchat_div {
    display: none;
}
</style>