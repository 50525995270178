<template>
  <div class="encycol-index">
    <div class="main-business">
      <div class="bus-nav">
        <div class="navBarUl" ref="navBarUl">
          <span
            class="item"
            @click="getListByType(item.id)"
            :class="{'active': cttrType == item.id}"
            v-for="item in typedata"
            :key="item.id"
          >{{item.name}}</span>
        </div>
      </div>
      <div class="navBar-line"></div>
      <template v-if="cttrType == 1">
        <div class="bus-list">
          <div class="cont">
            <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">什么是毕业生人事代理</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div class="con-advantage_">
                毕业生人事代理是国家倡导的新型人事管理制度。是由政府审批的有相应人事权限的单位或企业，按照国家有关人事政策法规要求，本着充分
                尊重毕业何时能自主择业的原则，高效公正、负责地为各类毕业生解决在择业、就业中遇到的人事方面的有关问题，并提供以档案管理为基础的社会人事管理与服务
              </div>
            </div>
          </div>
          <div class="cont">
            <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">哪些毕业生适合人事代理</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div class="who-advantage">
                <div class="who-content">
                  <div class="num">1、</div>
                  <div class="text">毕业离校前，未找到合适工作的毕业生；</div>
                </div>
                <div class="who-content">
                  <div class="num">2、</div>
                  <div class="text">已落实就业单位，但单位不能为其解决档案和户口问题的毕业生；</div>
                </div>
                <div class="who-content">
                  <div class="num">3、</div>
                  <div class="text">不想把人事关系、档案关系等落在用人单位，以防离职时受到单位的牵制和约束的毕业生；</div>
                </div>
                <div class="who-content">
                  <div class="num">4、</div>
                  <div class="text">考研成绩不太理想，计划下一年继续参加研究生考试的毕业生；</div>
                </div>
                <div class="who-content">
                  <div class="num">5、</div>
                  <div class="text">公考、事业编需要政审的毕业生；</div>
                </div>
              </div>
            </div>
          </div>
          <div class="cont">
            <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">办理人事代理的好处有哪些</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div class="advantage-item">
                <div class="item-title">
                  <img src="@/assets/views/gallery/huangguan.png" alt />
                  <span>优势一</span>
                </div>
                <div class="item-content">可免去毕业生回生源地进行实名确认的手续，为不在本地的 毕业生节省了时间和金钱</div>
              </div>
              <div class="advantage-item">
                <div class="item-title">
                  <img src="@/assets/views/gallery/huangguan.png" alt />
                  <span>优势二</span>
                </div>
                <div class="item-content">人事代理的毕业生在派遣期内找到新工作单位的，可协助办 理改派手续至新工作单位，并协助将其档案转至新工作单位。</div>
              </div>
              <div class="advantage-item">
                <div class="item-title">
                  <img src="@/assets/views/gallery/huangguan.png" alt />
                  <span>优势三</span>
                </div>
                <div class="item-content">毕业生代理档案满一年后，可根据国家规定为其办理档案整 理归档事宜，有利于以后考取公务员、事业编、退休时工龄 的计算。</div>
              </div>
              <div class="advantage-item">
                <div class="item-title">
                  <img src="@/assets/views/gallery/huangguan.png" alt />
                  <span>优势四</span>
                </div>
                <div
                  class="item-content"
                >办理人事代理可落户青岛市市区户口，落户后可享受与青岛 当地居民同等待遇，不受青岛限购政策影响，可申请经济适 用房、限价房和公租</div>
              </div>
              <div class="advantage-item">
                <div class="item-title">
                  <img src="@/assets/views/gallery/huangguan.png" alt />
                  <span>优势五</span>
                </div>
                <div class="item-content">可为人事代理的毕业生考研复试时免费提供相关证明材料， 并在录取后协助毕业生调取档案。</div>
              </div>
              <div class="advantage-item">
                <div class="item-title">
                  <img src="@/assets/views/gallery/huangguan.png" alt />
                  <span>优势六</span>
                </div>
                <div class="item-content">免费提供人力资源和劳动事务咨询业务，以便于更好的保护 自己的合法权益。</div>
              </div>
            </div>
          </div>
          <div class="navBar-line"></div>
          <div class="cont">
           <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">人事代理为毕业生提供哪些服务</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div class="gallery-box">
                <div class="gallery">
                  <div class="gallery-num">1</div>
                  <div class="gallery-text">毕业生签约、三方协议签订；</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">2</div>
                  <div class="gallery-text">档案托管及档案材料的收集、鉴别、整理和保管；</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">3</div>
                  <div class="gallery-text">档案查阅，以档案材料为依据的各类政审、证明材料出具；</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">4</div>
                  <div class="gallery-text">为升学、就业的人事代理毕业生办理档案转递、人事手续调动；</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">5</div>
                  <div class="gallery-text">各类证明出具：档案存档证明、政审证明、无业证 明、考研工作证明等；</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">6</div>
                  <div class="gallery-text">为符合条件的毕业生办理职称报名及评定服务；</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">7</div>
                  <div class="gallery-text">提供人事政策、法律法规咨询。</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">8</div>
                  <div class="gallery-text">办理青岛落户手续</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- 报到证 -->
      <template v-if="cttrType == 2">
        <div class="bus-list">
          <div class="cont">
           <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">什么是报到证</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div class="con-advantage_">
                也就是就业报到证。是应届普通高学毕业生到就业单位报到的凭证，也是毕业生参加工作时间的初始记载和凭证。毕业生到就业单位报到时，
                须持“报到证”。报到证由教育部印制，份上下两联，上联毕业生本人报到使用，下联由学校装入毕业生档案。
                <p>报到证是毕业生转移人事档案关系和户口关系的凭证。</p>
              </div>
            </div>
          </div>
          <div class="cont">
            <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">主要用途主要包括</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div class="gallery-box">
                <div class="gallery">
                  <div class="gallery-num">1</div>
                  <div
                    class="gallery-text"
                  >“就业报到证”是毕业生到单位报到的证明。毕业生 到工作单位就业时，须持“就业报到证”。用人单位 凭“就业报到证”为毕业生办理手续</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">2</div>
                  <div class="gallery-text">当地公安部门凭“就业报到证”为毕业生办理落户 手续。</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">3</div>
                  <div class="gallery-text">学校相关部门依据“就业报到证”为毕业生办理档案 投递、组织关系转移和户籍迁移等手续。</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">4</div>
                  <div
                    class="gallery-text"
                  >“就业报到证”正页由毕业生到用人单位报到时交给 用人单位，是毕业生参加工作时间的初始记载和凭 证，上面的日期是工龄的开始年限，与退休年龄和养老保险缴纳年数都有关。</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">5</div>
                  <div class="gallery-text">“就业报到证”是毕业生报考公务员必备资料。</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">6</div>
                  <div
                    class="gallery-text"
                  >“就业报到证”是毕业生就业的证明，“就业报到证” 中的姓名须与毕业生身份证中的姓名一致，单位的 名称也必须准确。“就业报到证”的有效期一般为毕业后三年内。</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">7</div>
                  <div class="gallery-text">非全日制、参加自考或成教没有报到证，只有统招 高校毕业生才有报到证。</div>
                </div>
              </div>
            </div>
          </div>
          <div class="cont">
          <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">"就业报到证"改派</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div class="baodao-box">
                <div class="baodao-title">"就业报到证"可以改派，改派手续为：</div>
                <div class="baodao-content">
                  <div class="content-item">
                    <div class="item-num">01</div>
                    <div class="item-text">用人单位在毕业生改派表或就业协议书上盖章；</div>
                  </div>
                  <div class="content-line"></div>
                  <div class="content-item" style="margin-top:0">
                    <div class="item-num">02</div>
                    <div class="item-text">用人单位上级主管部门在毕业生就业协议书上盖章；</div>
                  </div>
                  <div class="content-line"></div>
                  <div class="content-item" style="margin-top:0">
                    <div class="item-num">03</div>
                    <div class="item-text">人社处审核后，报省教育厅审批。</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- 档案 -->
      <template v-if="cttrType == 3">
        <div class="bus-list">
          <div class="cont">
          <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">什么是档案</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div class="con-advantage_">
                档案记录一个人的主要经历、政治面貌、品德作风、学习和工作表现等个人情况的文件材料，起着凭证、依据和参考的作用。
                <p>应届毕业生档案里一般是学籍档案。包括高中学籍档案、高考成绩单、大学录取通知书、入学体检表、各学年成绩单、入党入团材料、获得的表。</p>
                <p>我们通常所说的档案是指人事档案，人事档案就是参加工作之后体现你工作履历的历史记录。而我们的学籍档案一般会被放入人事档案里。</p>
              </div>
            </div>
          </div>
          <div class="cont">
           <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">档案有什么作用</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div class="yanglao">
                档案与
                <span style="color:#3367ff">养老退休、工龄计算、职称评审、公务员政审、户籍调动、事业单位人事手续办理</span> 等息息相关。所以毕业生一定要重视、妥善处理自己的档案。
              </div>
            </div>
          </div>
          <div class="cont">
           <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">毕业后，档案都去向哪里</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div class="gallery-box">
                <div class="gallery">
                  <div class="gallery-num">1</div>
                  <div class="gallery-text">未找到工作可暂时存放学校，两年择业期后，学校 会将档案打回原籍。所以不建议将档案寄放学校。</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">2</div>
                  <div class="gallery-text">找到工作的寄送单位。一般公司把员工档案统一存 放当地人才市场。建议档案不要归公司管理，以防 离职时档案被扣留。</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">3</div>
                  <div
                    class="gallery-text"
                  >主动打回原籍。无论是否参加工作，都可以将档案 放回原籍人事局或人才市场。如果不打算回原籍工 作，不建议将档案打回原籍。</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">4</div>
                  <div class="gallery-text">在就业地以个人名义委托有档案管理权限的单位托 管。这种寄存方式方便、灵活，不受用人单位束缚</div>
                </div>
              </div>
            </div>
          </div>
          <div class="cont">
            <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">档案管理错误的做法</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div class="advantage-item">
                <div class="item-title">
                  <div class="item-img">
                    <img src="@/assets/views/gallery/error.png" alt />
                  </div>
                  <div class="item-text">把档案保留在自己手里,会导致档案成为“死档”,不能正常流通。</div>
                </div>
                <div class="item-title" style="margin-top:10px;">
                  <div class="item-img">
                    <img src="@/assets/views/gallery/error.png" alt />
                  </div>
                  <div class="item-text">擅自拆封，一旦拆封，没有单位愿意接收了，因为里面资 料的真实性无法确定。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- 劳动合同 -->
      <template v-if="cttrType == 4">
        <div class="bus-list">
          <div class="cont">
          <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">什么是劳动合同</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div
                class="con-advantage_"
              >劳动合同 ，是指劳动者与用人单位之间确立劳动关系，明确双方权利和义务的协议，是双方合同。劳动合同与三方协议不同。三方协议是三方合同。它涉及学校、用人单位、学生等三方面，三方相互关联但彼此独立。</div>
            </div>
          </div>
          <div class="cont">
            <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">劳动合同与三方协议的区别</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div class="cont-introduce">
                <div class="introduce-title">签订时间不同</div>
                <div class="introduce-cont">三方协议是学生在校期间签订的，而劳动合同是在毕业生 毕业离校后到单位正式报到后签订的。</div>
              </div>
              <div class="cont-introduce">
                <div class="introduce-title">主体不同</div>
                <div class="introduce-cont">三方协议的主体是三方，即学校、毕业生和用人单位;而劳动合同的主体是两方，即劳动者和用人单位。</div>
              </div>
              <div class="cont-introduce">
                <div class="introduce-title">内容不同</div>
                <div
                  class="introduce-cont"
                >三方协议的主要内容是毕业生如实介绍自身情况，并表示 愿意到用人单位就业、用人单位表示愿意接收毕业生，学 校同意推荐毕业生并列入就业方案；而劳动合同是记载劳动者和用人单位的权利和义务，是劳动关系确立的法律凭证。</div>
              </div>
              <div class="cont-introduce">
                <div class="introduce-title">目的不同</div>
                <div
                  class="introduce-cont"
                >三方协议是毕业生和用人单位关于将来就业意向的初步约 定，是编制毕业生就业方案和将来双方订立劳动合同的依 据。而劳动合同主要是劳动关系确立后使劳动者和用人单位的合法权益得到应有的保障。</div>
              </div>
              <div class="cont-introduce">
                <div class="introduce-title">适用的法律不同</div>
                <div
                  class="introduce-cont"
                >三方协议的制定、发生争议后的解决主要依据是《国家关 于高校毕业生就业的规定》、《民法》、《合同法》等， 而劳动合同的订立以及发生争议后主要是依据《劳动法》和《劳动合同法》来解决。</div>
              </div>
            </div>
          </div>
          <div class="cont">
          <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">签劳动合同要注意四个方面</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div class="gallery-box" style="padding-top:5px">
                <div class="gallery">
                  <div class="gallery-num">1</div>
                  <div class="gallery-text">要明确职务和岗位。否则，用人单位会利用调职的 方式，变相压迫毕业生主动辞职，且不支付任何经 济补偿金；</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">2</div>
                  <div
                    class="gallery-text"
                  >要防止用人单位不断用换岗位的方式，反复延长试 用期，因为同一个岗位同一个人不能适用两次试用 期，而换岗位就没有限制。根据2008年1月1日生效的《劳动合同法》同一用人单位与统一劳动者只能约定一次试用期</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">3</div>
                  <div class="gallery-text">要在劳动合同中明确最低的工资标准。</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">4</div>
                  <div class="gallery-text">要了解用人单位是否给员工办理社会保险。如果没 有社保，等于工资减去很多，还不能享受国家和单 位的社保福利。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- 三方协议 -->
      <template v-if="cttrType == 5">
        <div class="bus-list">
          <div class="cont">
            <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">什么是三方协议</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div class="con-advantage_">
                三方协议的全称是《普通高等学校毕业生、毕业研究生就业协议书》，它是明确毕业生、用人单位和学校三方在毕业生就业工作中的权利和义务的书面表现形式，能解决应届毕业生户籍、档案、社会保险、公积金等一系列相关问题。
                <p>三方协议在毕业生到单位报到、用人单位正式接收后自行终止。也就是说它的有效期是从签约日起到毕业生到用人单位报到止。毕业生报到须持《全国毕业生研究生报到证》。</p>
              </div>
            </div>
          </div>
          <div class="cont">
            <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">签订三方协议有什么意义</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div class="gallery-box" style="padding-top:5px">
                <div class="gallery">
                  <div class="gallery-num">1</div>
                  <div
                    class="gallery-text"
                  >三方协议是国家统计大学生就业率的一个根据，一 旦签署，就意味着大学生第一份工作基本确定。同 时三方协议也是发放报到证的一个证明；只有你签署了三方协议，拿回学校，学校才会在你毕业后将报道证发给你，而你拿着报到证到你工作的单位报道，就此开始计算工龄</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">2</div>
                  <div class="gallery-text">三方协议≠劳动合同</div>
                </div>
                <div class="gallery">
                  <div class="gallery-num">3</div>
                  <div
                    class="gallery-text"
                  >三方协议书只是毕业生、用人单位、学校三方之间 签订的就业意向，不是劳动合同，对用人单位和毕 业生没有约束力。有的企业在和学生签订三方协议后，要求学生毕业前到公司实习，企业在学生毕业后根据实习表现协议签订劳动合同，也可能出现不签订的情况。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- 改派 -->
      <template v-if="cttrType == 6">
        <div class="bus-list">
          <div class="cont">
           <div class="adv-title">
              <img src="@/assets/views/mine/advant.jpg" alt />
              <span class="adv-">什么是改派</span>
              <img src="@/assets/views/mine/advant.jpg" alt />
            </div>
            <div class="cont-advantage">
              <div
                class="con-advantage_"
              >改派手续是指毕业生离校时已落实用人单位，且就业报到证也已签发到用人单位的，在改派期内(自毕业之日起两年内)，因特殊情况与原用人的，在改派期内（自毕业之日起两年内），因特殊情况与原用人单位接触就业协议，申请回户籍地，或与新用人单位签订就业协议并完成就业信息网上登记后，有毕业生就业主管部门办理的就业手续。其中，考录到机关单位的，应当提供有关录取或者聘用通知书。</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import "@/sass/views/gallery/small-encyclopedia.scss";
export default {
  name: "index",
  data() {
    return {
      typedata: [
        {
          id: 1,
          name: "毕业人事代理"
        },
        {
          id: 2,
          name: "报到证"
        },
        {
          id: 3,
          name: "档案"
        },
        {
          id: 4,
          name: "劳动合同"
        },
        {
          id: 5,
          name: "三方协议"
        },
        {
          id: 6,
          name: "改派"
        }
      ],

      cttrType: "",
      loadingFullscreen: ""
    };
  },
  mounted() {
    this.iniBusiness();
    this.cttrType = this.$route.query.id;
  },

  methods: {
    getListByType(id) {
      this.cttrType = id;
    },
    /**
     * 初始化业务大类滚动
     */
    /* eslint-disable */
    iniBusiness() {
      this.$nextTick(() => {
        let winWidth = document.documentElement.clientWidth;
        let navBarUl = this.$refs.navBarUl;
        let oLi = navBarUl.getElementsByClassName("item");
        navBarUl.style.width = "3000px";
        var allListWidth = 0;
        for (let i = 0; i < oLi.length; i++) {
          allListWidth += oLi[i].offsetWidth;
        }
        if (this.cttrType >= 2) {
          navBarUl.style.marginLeft = "-69" + "px";
        }
        navBarUl.style.width = allListWidth + 20 + "px";
        let startX = 0;
        let cuttrX = 0;
        navBarUl.style.transition = "all .5s";
        navBarUl.addEventListener("touchstart", e => {
          //e就是事件
          startX = e.targetTouches[0].pageX;
          cuttrX = navBarUl.offsetLeft;
        });
        navBarUl.addEventListener("touchmove", e => {
          let leftpull = winWidth - allListWidth;
          if (navBarUl.style.transition.split(" ")[0] != "none") {
            navBarUl.style.transition = "none";
          }
          //移动的差值
          let tempRutn = e.targetTouches[0].pageX - startX;
          //nav距左位置
          let mlNum = cuttrX + tempRutn;
          if ((mlNum >= 0 && tempRutn > 0) || leftpull > 0) {
            navBarUl.style.marginLeft = "0";
          } else if (tempRutn < 0 && navBarUl.offsetLeft <= leftpull) {
            navBarUl.style.marginLeft = leftpull - 30 + "px";
          } else {
            navBarUl.style.marginLeft = cuttrX + tempRutn + "px";
          }
          navBarUl.addEventListener("touchend", e => {
            //e就是事件
            navBarUl.style.transition = "all .5s";
          });
        });
      });
    }
  }
};
</script>
