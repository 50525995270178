<!--
 * @Description: 养老保险
 * @Autor: bjp
 -->
<template>
  <div class="pension">
    <div class="banner">
      <span>养老保险</span>
    </div>
    <div class="proportion">
      <div class="title">
        <img src="@/assets/views/question/pension/1.png" alt="">
        <span>单位和个人缴费比例</span>
      </div>
      <ul class="peoCompany">
        <li>
          <p>8%</p>
          <p>个人缴费</p>
        </li>
        <li>
          <p>18%</p>
          <p>单位缴费</p>
        </li>
      </ul>
    </div>
    <div class="agePay">
      <div class="title">
        <img src="@/assets/views/question/pension/2.png" alt="">
        <span>养老金计算方法</span>
      </div>
      <div class="desc">
        <div class="base">
          每月退休工资=<span>基础养老金</span>+<span>个人账户养老金</span>
        </div>
        <div class="content">
          <p>基础养老金和个人账户养老金如何计算呢？这里有公式：</p>
          <p>基础养老金=</p>
          <p>(本人退休时全省上一年度在岗职工月平均工资+本人指数化</p>
          <p>月平均缴费工资)/2×缴费年限×1%</p>
          <p>个人账户养老金=</p>
          <p>个人实际账户累计额/计发月数(60岁退休按139个月计算)</p>
        </div>
        <div class="example1">
          <div class="dividingLine">
            <div class="premuse">假设</div>
            <div class="line"></div>
          </div>
          <div class="content">
            <p>老王60岁退休，退休时个人账户里有139000元。个人账户</p>
            <p>养老金=139000 / 139= 1000 元。</p>
            <p>基础养老金为缴费年限 × 1% × 退休那一年的当地社会平均</p>
            <p>月工资</p>
          </div>
        </div>
        <div class="example2">
          <div class="dividingLine">
            <div class="premuse">假设</div>
            <div class="line"></div>
          </div>
          <div class="content">
            <p>当地社会平均月工资为6000元、缴费年限25年</p>
            <p>基础养老金 = 25年 * 1% * 6000元 = 1500 元/月</p>
            <div class="warning">
              <div class="one">
                <div class="warntitle">
                  <img src="@/assets/views/question/pension/warning.png" alt="">
                  <span>注意1：</span>
                </div>
                <p>这是假设一直按当地平均工资水平缴费的,缴费高的话,1%</p>
                <p>这个数字(依据个人缴费基数算出)会高，比如1.2%,缴费</p>
                <p>低的话,会不足1%,比如0.7% 。</p>
              </div>
              <div class="one">
                <div class="warntitle">
                  <img src="@/assets/views/question/pension/warning.png" alt="">
                  <span>注意2：</span>
                </div>
                <p>一般是1992年才开始个人缴费。1992年之前的工龄,只要</p>
                <p>有合同、档案等证明,全部算做已经缴费,叫做视同缴费年</p>
                <p>两项合计是每月:1000+1500=2500元/月</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position">
      <div class="title">
        <img src="@/assets/views/question/pension/3.png" alt="">
        <span>养老保险领取地确认</span>
      </div>
      <ul class="scale">
        <li>
          <p class="titles">养老保险累计缴纳</p>
          <p class="years">满15年</p>
        </li>
        <li>
          <p class="titles">同一地区累计缴纳</p>
          <p class="years">满10年</p>
        </li>
      </ul>
      <ol type="1">
        <li>
          <p>养老保险累计缴纳已满15年，且在同一地区缴纳满10年可</p>
          <p>在该地区办理待遇领取，也可在户籍地办理待遇领取</p>
        </li>
        <li>
          <p>跨省流动就业中参保地无一处累计缴纳满10年但总缴纳时</p>
          <p>间已满15年可在户籍地办理养老保险补缴或者延迟退休</p>
        </li>
      </ol>
    </div>
    <div class="condition">
      <div class="title">
        <img src="@/assets/views/question/pension/4.png" alt="">
        <span>青岛养老满足条件</span>
      </div>
      <div class="desc">
        <div class="content">
          <p>达到退休年龄并且满足退休条件，需提供以下资料</p>
          <ol>
            <li>户口本</li>
            <li>身份证</li>
            <li>退休申请表</li>
            <li>职工档案</li>
          </ol>
        </div>
        <div class="content">
          <p>特殊情况需提供以下资料</p>
          <ol>
            <li>劳动能力鉴定（病退）</li>
            <li>特殊工种证明材料（特殊工种退休）</li>
          </ol>
        </div>
        <div class="content">
          <p>目前职工社保参保人的退休年龄如下</p>
          <ol>
            <li>男职工60周岁</li>
            <li>女干部55周岁</li>
            <li>女职工50周岁</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- 浮动 -->
    <common :url="url" ref="common" state="1" type="1"></common>
  </div>
</template>
<script>
import "@/sass/views/question/pension.scss"
import common from "@/views/questions/common/CommonPolicy"
export default {
  components:{
    common
  },
  data(){
    return {
      url:'/socialSecurity/pensionList',
    }
  },
  beforeRouteEnter(to,from,next){
    next( _this =>{
      if(from.path == _this.url){
        _this.$refs.common.changeToLink()
      }
    }
    )
  },
  methods:{}
}
</script>