<template>
  <div class="use-index">
    <div class="banner-con">
      <div class="banner">
        <div class="title-banner">落户有好处</div>
        <div class="banner-introduce">落户可享受多重好处便利</div>
      </div>
    </div>
    <div class="main-business">
      <div class="bus-nav">
        <div class="navBarUl" ref="navBarUl">
          <span
            class="item"
            @click="getListByType(item.id)"
            :class="{'active': cttrType == item.id}"
            v-for="item in typedata"
            :key="item.id"
          >{{item.name}}</span>
          <div class="clear"></div>
        </div>
      </div>
    </div>
    <template v-if="cttrType == 1">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <span>购房</span>
          </div>
          <div class="use-cont">
            <span>青岛目前实行限购政策，政策规定，非本地户口在青岛买房需要连续缴纳12个月以上的社保且仅限购买一套住房，但是有青岛户口，就可以直接买房，没有社保要求，青岛户口以家庭为单位最多可以购买两套。这样一来二去就能省下一大笔钱用作他用。随着青岛房价的上涨，有能力的情况下多投资一套房子也有很大的升值空间。</span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 2">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <span>孩子上学</span>
          </div>
          <div class="use-cont">
            <span>青岛公办学位紧张，尤其一些热门的重点公办学校资源。符合年龄的儿童在青岛入园入学，学校优先录取有青岛户口的学区范围内的孩子。如果没有青岛户籍，孩子那就只能以外来务工人员子女的身份入学，可供选择的学校、学位机会很少，而且入学需要提交的材料也相对比较繁琐。</span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 3">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <span>公租房</span>
          </div>
          <div class="use-cont">
            <span>为了解决住房有困难人群的住房难题，青岛一直在推行公租房政策。公租房归政府或公共机构所有，而不是归个人，所以房租价格要低于普通商品房租赁的市场价格。因此，对于一些收入低、住房有困难的人员来说，租赁公租房是一个很好的选择。在青岛，申请公租房要有本市户口才满足条件。有的区域甚至规定青岛户口必须满一定年限才符合申请条件。</span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 4">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <span>经济适用房</span>
          </div>
          <div class="use-cont">
            <span>青岛的房价一直处于较高的状态，很多人奋斗一辈子也买不起一套商品房。经济适用房是政府为了解决中低收入人群的住房问题而建设的保障性住房，因此价格远低于市场上的同地段商品房。在青岛，申请经济适用房要求5年以上的本地户口。</span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 5">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <span>人才公寓</span>
          </div>
          <div class="use-cont">
            <span>为吸引人才、解决引进人才的住房困难问题，青岛大力推进人才公寓及高层次人才住房建设工作，符合条件的引进人才均可申请租赁或购买人才公寓。根据2021年新政，符合条件的人才可八折购买青岛人才公寓房。毕业学年在校大学生办理人才落户后，也可以申请人才公寓。</span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 6">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <span>住房补贴</span>
          </div>
          <div class="use-cont">
            <span>青岛住房补贴主要针对选择来青岛就业青年人才。2018年及以后毕业，派遣期内统招全日制本科学历毕业生；2018年及以后毕业且首次来青就业，并取得教育部留学服务中心国(境)外学历学位认证的留学回国本科学历毕业生。具有青岛户口，在青岛正常缴纳社会保险的，根据本科学历、研究生学历不同，可申领最低500元/月、最高1500元/月的住房补贴，最长可领36个月。</span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 7">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <span>创业补贴</span>
          </div>
          <div class="use-cont">
            <span>又到一年高校毕业季，离开校园的学子，不少会选择创业之路。青岛针对大学生创业主要有三类扶持政策。第一类是一次性创业补贴。毕业5年内的大中专毕业生等人员，在青岛市创办各类创业实体，最高可申领3万元一次性创业补贴。第二类是一次性创业岗位开发补贴，每人2000元的标准。第三类是创业担保贷款及贴息。在青岛市创办个体工商户的，可申领最高15万元创业担保贷款，创办企业等创业实体的，可申领最高45万元创业担保贷款，以上贷款最高可享受3年全额贴息。</span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="cttrType == 8">
      <div class="cont-use">
        <div class="use-cont">
          <div class="use-introduce">
            <span>共有产权房</span>
          </div>
          <div class="use-cont">
            <span>根据城阳区出台引进人才购置共有产权房方案，具有青岛城阳区户籍且满足青岛市人才类别规定的七类人才或具有非城阳区的青岛市户籍人才，在青岛其他区市有一套住房的也可以申请城阳共有产权房。其中1-4类人才本人与企业的购房出资比例为7：3，个人在城阳区内工作并享受共有产权政策满8年后无偿获赠该房屋剩余30%产权；5-7类人才本人与企业的购房出资比例为8：2，自办理完购房网签之日起满5年后由个人将剩余20%的产权回购；</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import "@/sass/views/mine/hkhuanli-item.scss";
export default {
  name: "archives-use",
  data() {
    return {
      typedata: [
        {
          id: 1,
          name: "购房"
        },
        {
          id: 2,
          name: "孩子上学"
        },
        {
          id: 3,
          name: "公租房"
        },
        {
          id: 4,
          name: "经济适用房"
        },
        {
          id: 5,
          name: "人才公寓"
        },
        {
          id: 6,
          name: "住房补贴"
        },
        {
          id: 7,
          name: "创业补贴"
        },
        {
          id: 8,
          name: "共有产权房"
        }
      ],
      cttrType: ""
    };
  },
  mounted() {
    this.iniBusiness();
    this.getListByType(1);
    this.cttrType = this.$route.query.id;
  },
  methods: {
    /**
     * 初始化业务大类滚动
     */
    /* eslint-disable */
    iniBusiness() {
      this.$nextTick(() => {
        let winWidth = document.documentElement.clientWidth;

        let navBarUl = this.$refs.navBarUl;
        let oLi = navBarUl.getElementsByClassName("item");
        navBarUl.style.width = "3000px";
        var allListWidth = 0;
        for (let i = 0; i < oLi.length; i++) {
          allListWidth += oLi[i].offsetWidth;
        }
        navBarUl.style.width = allListWidth + 180 + "px";
        let startX = 0;
        let cuttrX = 0;
        navBarUl.style.transition = "all .5s";
        navBarUl.addEventListener("touchstart", e => {
          //e就是事件
          startX = e.targetTouches[0].pageX;
          cuttrX = navBarUl.offsetLeft;
        });
        if (this.cttrType > 5) {
          navBarUl.style.marginLeft = "-400" + "px";
        }else if(this.cttrType == 5){
          navBarUl.style.marginLeft = "-370" + "px";
        }
        navBarUl.addEventListener("touchmove", e => {
          let leftpull = winWidth - allListWidth;
          if (navBarUl.style.transition.split(" ")[0] != "none") {
            navBarUl.style.transition = "none";
          }
          //移动的差值
          let tempRutn = e.targetTouches[0].pageX - startX;
          //nav距左位置
          let mlNum = cuttrX + tempRutn;
          if ((mlNum >= 0 && tempRutn > 0) || leftpull > 0) {
            navBarUl.style.marginLeft = "0";
          } else if (tempRutn < 0 && navBarUl.offsetLeft <= leftpull) {
            navBarUl.style.marginLeft = leftpull - 180 + "px";
          } else {
            navBarUl.style.marginLeft = cuttrX + tempRutn + "px";
          }
          navBarUl.addEventListener("touchend", e => {
            //e就是事件
            navBarUl.style.transition = "all .5s";
          });
        });
      });
    },
    getListByType(id) {
      this.cttrType = id;
    }
  }
};
</script>

