<template>
  <div class="express-archives">
    <div class="top-bg">
      <img src="@/assets/views/expressmail/business-bg.png" class="bg" alt />
      <div class="show-tips">办理完签约手续的应届毕业生在毕业后一个月内请及时到我方办理报到手续</div>
    </div>
    <div class="express-item topsty" v-if="this.$route.query.type < 2">
      <div class="express-title">档案</div>
      <div class="item">
        <div class="lable">所需材料</div>
        <div class="content">
          <span class="content-detail">1.毕业生</span>
          <span class="content-detail">2.学位证</span>
          <span class="content-detail">3.报到证</span>
          <span class="content-detail">4.1寸彩色白底照片</span>
        </div>
      </div>
      <div class="item">
        <div class="lable">说明</div>
        <div class="content">上传所需注明证件即可完成报到手续</div>
      </div>
    </div>
    <div class="express-item topsty" v-else>
      <div class="express-title">户口/档案户口</div>
      <div class="item">
        <div class="lable">所需材料</div>
        <div class="content">
          <span class="content-detail">1.毕业生</span>
          <span class="content-detail">2.学位证</span>
          <span class="content-detail">3.报到证</span>
          <span class="content-detail">4.1寸彩色白底照片</span>
        </div>
        <div class="content">
          <span class="content-detail">5.户口本/户籍证明/户口迁移证</span>
          <span class="content-detail">6.身份证</span>
        </div>
        <div class="content">
          <span class="content-detail">7.学历注册备案表（学信网导出即可）</span>
        </div>
      </div>
      <div class="item">
        <div class="lable">说明</div>
        <div class="content">1.上传注明所需证件外须将原件邮寄/送达到我方，待我方处理完毕后将原件退回。如选择邮寄方式请邮寄到我方指定地址</div>
        <div class="content">2.选择邮寄方式的请将快递单号等信息填写完整以便我方及时查取反馈。</div>
      </div>
    </div>
    <div class="express-item">
      <div class="item">
        <div class="lable">收件人</div>
        <div class="add-info">
          <div class="add-title">姓名:</div>
          <div class="add-content">李青青</div>
        </div>
        <div class="add-info">
          <div class="add-title">联系电话:</div>
          <div class="add-content">0532-85873655转811或816</div>
          <div class="add-content" style='margin-top:10px;margin-left:80px;'>18661887826</div>
        </div>
        <div class="add-info">
          <div class="add-title">联系地址</div>
          <div class="add-content">青岛市市南区高雄路海洋大厦5层</div>
        </div>
      </div>
    </div>
    <div class="ex-submit" @click="goSend()">发起邮寄</div>
  </div>
</template>

<script>
import "@/sass/views/expressmail/expressarchives.scss";
import { mapGetters } from "vuex";
export default {
  name: "expressarchives",
   computed: {
    ...mapGetters({
      userInfo: "getUserInfo"
    })
  },
  methods: {
    /**
     * 确认邮寄
     */
    goSend() {
      if (this.userInfo.id == "") {
        this.$router.forward("/login/login");
        return;
      }
      this.$router.forward('/expressmail/index')
    },
  }
};
</script>