<template>
  <div>
    <div class="bigBox">
      <div class="backbox">
        <img src="@/assets/views/gallery/head.png" alt="">
        <div class="Written"><span>——</span> 工伤保险 <span>——</span></div>
      </div>
      <div class="box">
        <div class="Hierarchy1">
          <div>
            <img src="@/assets/views/gallery/Bed1.png" alt="">
            <div>单位和个人缴费比例</div>
          </div>
        </div>
        <div class="Hierarchy2">
          <div class="card">
            <div class="cardhead">
              单位缴费
            </div>
            <ul>
              <li>0.3%</li>
              <li>0.7%</li>
              <li>1.2%</li>
              <li>1.9%</li>
            </ul>
            行业种类不同
          </div>
          <div class="card2">
            <div class="cardhead2">
              个人缴费
            </div>
            <div class="cardcontent"><span>无需缴纳</span></div>
          </div>
        </div>
        <div class="Hierarchy3">
          <div>
            <img src="@/assets/views/gallery/Bed.png" alt="">
            <div class="Lining">工伤待遇赔偿标准</div>
          </div>
        </div>
        <div class="Hierarchy4">
          <ul class="btn1">
            <li :class="{'ctBtn':isShow}" @click="ctBtn1">1-4级一次性领取</li>
            <li :class="{'ctBtn':isShow1}" @click="ctBtn2">1-4级按月领取</li>
          </ul>
          <ul class="ctn1">
            <li class="level1">
              <div></div>
              <span v-if="isShow">1-4级一次性领取伤残补助金（单位：个月）</span>
              <span v-else>1-4级按月领取伤残津贴（单位：%）</span>
            </li>
            <li class="level2">
              <div v-if="isShow" id="table1"></div>
              <div v-else id="table2"></div>
              <div class="jibie">级别</div>
            </li>
            <li class="level3">
              <div v-if="isShow">领取公式：<span>本人本月工资</span>*<span>月份</span>=<span>总领取金额</span></div>
              <div v-else>领取公式：<span>本人本月工资</span>*<span>比例</span>=<span>月领取金额</span></div>
            </li>
          </ul>
        </div>
        <div class="interval"></div>
        <div class="Hierarchy5">
          <ul class="btn2">
            <li :class="{'ctBtn':isShow2}" @click="ctBtn3">5-6级一次性领取</li>
            <li :class="{'ctBtn':isShow3}" @click="ctBtn4">5-6级按月领取</li>
          </ul>
          <ul class="ctn2">
            <li class="level4">
              <div></div>
              <span v-if="isShow2">5-6级一次性领取伤残补助金（单位：个月）</span>
              <span v-else>5-6级按月领取伤残津贴（单位：%）</span>
            </li>
            <li class="level5">
              <div v-if="isShow2" id="table3"></div>
              <div v-else id="table4"></div>
              <div class="bfb">{{isShow2 ? "月份" :"百分比"}}</div>
            </li>
            <li class="level6">
              <div v-if="isShow2">领取公式：<span>本人本月工资</span>*<span>月份</span>=<span>总领取金额</span></div>
            </li>
          </ul>
        </div>
        <div class="interval"></div>
        <div class="Hierarchy6">
          <ul class="ctn3">
            <li class="level7">
              <div></div>
              <span>7-10级一次性领取伤残补助金（单位：个月）</span>
            </li>
            <li class="level8">
              <div id="table5"></div>
              <div class="jibie1">级别</div>
            </li>
            <li class="level9">
              <div>领取公式：<span>本人本月工资</span>*<span>月份</span>=<span>总领取金额</span></div>
              <div>解除或终止劳动关系时，由用人单位一次性支付给工伤职工</div>
            </li>
          </ul>
        </div>
        <div class="interval"></div>
        <div class="Hierarchy7">
          <div>
            <img src="@/assets/views/gallery/Bed2.png" alt="">
            <div>单位和个人缴费比例</div>
          </div>
        </div>
        <div class="Hierarchy8">
          <ul class="ctn4">
            <li class="level8">
              <div></div>
              <span>有下列情形之一的，可认定工伤</span>
            </li>
          </ul>
          <ul class="ctn5">
            <li>在工作时间和工作场所内，因工作原因受到事故伤害的</li>
            <li>工作时间前后在工作场所内，从事与工作有关的预备性或者收尾性工作受到事故伤害的</li>
            <li>在工作时间和工作场所内，因履行工作职责受到暴力等意外伤害的</li>
            <li>患职业病的</li>
            <li>因工外出期间，由于工作原因收到伤害或者发生事故下落不明的</li>
            <li>在上下班途中，受到非本人主要责任的交通事故或者城市轨道交通、客运轮渡、火车事故伤害的</li>
            <li>法律、行政法规规定应当认定为工伤的其他情形</li>
          </ul>
          <ul class="ctn6">
            <li class="level9">
              <div></div>
              <span>有下列情形之一的，视同工伤</span>
            </li>
          </ul>
          <ul class="ctn7">
            <li>在工作时间和工作岗位，突发疾病或者在48小时之内经抢救无效死亡的</li>
            <li>在抢险救灾等维护国家利益、公共利益活动中受到伤害的</li>
            <li>职工原在军队服役，因战，因公负伤致残，已取得革命伤残军人证，到用人单位后旧伤复发的</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 浮动 -->
    <common :url="url" ref="common" state="3" type="1"></common>
  </div>
</template>

<script>
import "@/sass/views/gallery/shebaofx.scss"
import common from "@/views/questions/common/CommonPolicy"
export default {
  name: "hello1",
  components:{
    common
  },
  data(){
    return {
      isShow:true,
      isShow1:false,
      isShow2:true,
      isShow3:false,
      url:'/gallery/hurtPensionList',
      msg: "Welcome to Your Vue.js App",
    }
  },
  beforeRouteEnter(to,from,next){
    next( _this =>{
      if(from.path == _this.url){
        _this.$refs.common.changeToLink()
      }
    }
    )
  },
  mounted(){
    this.drawLine();
    this.drawLine2();
    this.drawLine4();
  },
  methods:{

    ctBtn1(){
      this.isShow = true;
      this.isShow1 = false;
      this.$nextTick(() => {
        this.drawLine();
      });
    },
    ctBtn2(){
      this.isShow = false;
      this.isShow1 = true;
      this.$nextTick(() => {
        this.drawLine1();
      });
    },
    ctBtn3(){
      this.isShow2 = true;
      this.isShow3 = false;
      this.$nextTick(() => {
        this.drawLine2();
      });
    },
    ctBtn4(){
      this.isShow2 = false;
      this.isShow3 = true;
      this.$nextTick(() => {
        this.drawLine3();
      });
    },
     
    // 基于准备好的dom，初始化echarts实例
    drawLine(){
      // 基于准备好的dom，初始化echarts实例
      let myChart1 = this.$echarts.init(document.getElementById("table1"));
      // 绘制图表
      myChart1.setOption({
        backgroundColor: "#f5f5f5",
        color: ["#4162ff"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          //图表的位置
          top: "30%",
          left: "5%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        yAxis: [
          {
            type : 'value',
            scale : true,
            max : 30,
            min : 0,
            splitNumber : 4,
            name: "月份",
            nameTextStyle: {
              //---坐标轴名称样式
              color: "#999999",
              padding: [5, 0, 0, -23], //---坐标轴名称相对位置
              fontSize:"100%"
            },
            splitLine: { show: false },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#cccccc"
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize:"100%"
              }
            }
          }
        ],
        xAxis: [
          {
            type: "category",
            data: ["一级伤残", "二级伤残", "三级伤残", "四级伤残"],
            nameGap: 15,
            splitLine: { show: false },
            namelocation: "end",
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#cccccc"
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize:"100%"
              },
              interval: 0, //代表显示所有x轴标签显示
            }
          }
        ],

        nameTextStyle: {
          // 坐标轴名称样式
          color: "red",
          padding: [5, 0, 0, -5]
        },
        series: [
          {
            name: "级别",
            type: "bar",
            stack: "总量",
            barWidth: "30px",
            label: {
              show:true,
              position:'top',
              fontSize:"100%"
            },
            data: [27, 25, 23, 21]
          },
        ]
      });
      setTimeout(function (){
        window.onresize = function () {
            myChart1.resize();
          }
      },300)
    },
    drawLine1(){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("table2"));
      // 绘制图表
      myChart.setOption({
        backgroundColor: "#f5f5f5",
        color: ["#4162ff"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          //图表的位置
          top: "30%",
          left: "5%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        yAxis: [
          {
            type: 'value',
            data: ["0","70","80","90"],
            max:90,
            min:0,
            scale : true,
            splitNumber : 3,
            name: "百分比",
            nameTextStyle: {
              //---坐标轴名称样式
              color: "#999999",
              padding: [5, 0, 0, -23], //---坐标轴名称相对位置
              fontSize:"100%",
            },
            splitLine: { show: false },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#cccccc"
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize:"100%"
              }
            }
          }
        ],
        xAxis: [
          {
            type: "category",
            data: ["一级伤残", "二级伤残", "三级伤残", "四级伤残"],
            nameGap: 15,
            splitLine: { show: false },
            namelocation: "end",
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#cccccc"
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize:"100%"
              },
              interval: 0, //代表显示所有x轴标签显示
            }
          }
        ],

        nameTextStyle: {
          // 坐标轴名称样式
          color: "red",
          padding: [5, 0, 0, -5]
        },
        series: [
          {
            name: "级别",
            type: "bar",
            stack: "总量",
            barWidth: "30px",
            label: {
              show:true,
              position:'top',
              fontSize:"100%"
            },
            data: [90, 85, 80, 75]
          },
        ]
      });
      setTimeout(function (){
        window.onresize = function () {
            myChart.resize();
          }
      },200)
    },
    drawLine2(){
      // 基于准备好的dom，初始化echarts实例
      let myChart1 = this.$echarts.init(document.getElementById("table3"));
      // 绘制图表
      myChart1.setOption({
        backgroundColor: "#f5f5f5",
        color: ["#4162ff"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          //图表的位置
          top: "30%",
          left: "12%",
          right: "4%",
          bottom: "5%",
          containLabel: true
        },
        yAxis: [
          {
            type: 'category',
            data: ["六级伤残","五级伤残"],
            name: "级别",
            nameTextStyle: {
              //---坐标轴名称样式
              color: "#999999",
              padding: [5, 0, 0, -35], //---坐标轴名称相对位置
              fontSize:"100%"
            },
            splitLine: { show: false },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#cccccc"
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize:"100%"
              }
            }
          }
        ],
        xAxis: [
          {
            type: "value",
            nameGap: 15,
            splitLine: { show: false },
            namelocation: "end",
            max:20,
            min:0,
            scale : true,
            splitNumber : 5,
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#cccccc"
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize:"100%"
              },
              interval: 0, //代表显示所有x轴标签显示
            }
          }
        ],

        nameTextStyle: {
          // 坐标轴名称样式
          color: "red",
          padding: [5, 0, 0, -5]
        },
        series: [
          {
            name: "月份",
            type: "bar",
            stack: "总量",
            barWidth: "30px",
            label: {
              show:true,
              position:'right',
              fontSize:"100%"
            },
            data: [16,18]
          },
        ]
      });
      setTimeout(function (){
        window.onresize = function () {
            myChart1.resize();
          }
      },200)
    },
    drawLine3(){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("table4"));
      // 绘制图表
        myChart.setOption({
          backgroundColor: "#f5f5f5",
          color: ["#4162ff"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            }
          },
          grid: {
            //图表的位置
            top: "30%",
            left: "12%",
            right: "3%",
            bottom: "5%",
            containLabel: true
          },
          yAxis: [
            {
              type: 'category',
              data: ["六级伤残","五级伤残"],
              splitLine: { show: false },
                    nameTextStyle: {
        //---坐标轴名称样式
        color: "#999999",
        padding: [5, 0, 0, -23], //---坐标轴名称相对位置
        fontSize:"100%",
      },
              axisTick: {
                show: false
              },
              axisLine: {
                lineStyle: {
                  color: "#cccccc",
                  fontSize:'100%'
                }
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#333333",
                  fontSize:"100%"
                }
              }
            }
          ],
          xAxis: [
            {
              type: "value",
              nameGap: 15,
              splitLine: { show: false },
              namelocation: "end",
              max:80,
              min:0,
              scale : true,
              splitNumber : 5,
              axisTick: {
                show: false
              },
              axisLine: {
                lineStyle: {
                  color: "#cccccc",
                  fontSize:"80%"
                }
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#333333",
                  fontSize:"100%"
                },
                interval: 0, //代表显示所有x轴标签显示
              }
            }
          ],

          nameTextStyle: {
            // 坐标轴名称样式
            color: "red",
            padding: [5, 0, 0, -5]
          },
          series: [
            {
              name: "百分比",
              type: "bar",
              stack: "总量",
              barWidth: "30px",
              label: {
                show:true,
                position:'right',
                fontSize:"100%"
              },
              data: [70,60]
            },
          ]
        });
        setTimeout(function (){
          window.onresize = function () {
              myChart.resize();
            }
        },300)
    },
    drawLine4(){
    // 基于准备好的dom，初始化echarts实例
    let myChart = this.$echarts.init(document.getElementById("table5"));
    // 绘制图表
      myChart.setOption({
        backgroundColor: "#f5f5f5",
        color: ["#4162ff"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {
          //图表的位置
          top: "30%",
          left: "5%",
          right: "4%",
          bottom: "10%",
          containLabel: true
        },
        yAxis: [
          {
            type : 'value',
            scale : true,
            max : 15,
            min : 0,
            splitNumber : 3,
            name: "月份",
            nameTextStyle: {
              //---坐标轴名称样式
              color: "#999999",
              padding: [5, 0, 0, -23], //---坐标轴名称相对位置
              fontSize:"100%",
            },
            splitLine: { show: false },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#cccccc"
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize:"100%"
              }
            }
          }
        ],
        xAxis: [
          {
            type: "category",
            data: ["七级伤残", "八级伤残", "九级伤残", "十级伤残"],
            nameGap: 15,
            splitLine: { show: false },
            namelocation: "end",
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#cccccc"
              }
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#333333",
                fontSize:"100%"
              },
              interval: 0, //代表显示所有x轴标签显示
            }
          }
        ],

        nameTextStyle: {
          // 坐标轴名称样式
          color: "red",
          padding: [5, 0, 0, -5]
        },
        series: [
          {
            name: "级别",
            type: "bar",
            stack: "总量",
            barWidth: "30px",
            label: {
              show:true,
              position:'top',
              fontSize:"100%"
            },
            data: [13, 11, 9, 7]
          },
        ]
      });
      setTimeout(function (){
        window.onresize = function () {
            myChart.resize();
          }
      },200)
    },
    touchstart(ev){
      this.initY = ev.targetTouches[0].pageY
    },
    touchmove(ev){
      ev.preventDefault()
      this.moveY = this.initY - ev.targetTouches[0].pageY
      if(this.moveY < 0){
        this.$refs.touchIt.style.transform = 'translateY('+ this.$refs.touchIt.offsetHeight +'px)'
        this.$refs.floating.style.opacity = 1
      }
    }
  }
}
</script>