<!--
 * @Description: 悬浮窗
 * @Autor: bjp
 -->
<template>
  <div class="floatWindow">
    <ul>
      <li
        v-for="item in showData"
        :key="item.id"
        @click="showList(item.id)">
        <img :src="require('@/assets/views/question/float'+item.id+'.png')" alt="">
        <span>{{item.title}}</span>
      </li>
      <li @click="showList()">
        <img src="@/assets/views/question/float4.png" alt="">
      </li>
    </ul>
  </div>
</template>
<script>
import "@/sass/views/question/float.scss"
export default {
  props:{
    url:{
      type:String,
      default:'/'
    },
  },
  data(){
    return {
      showData:[
        {id:1,title:'政策'},
        {id:2,title:'资讯'},
        {id:3,title:'常见问题'},
      ],
      zixuntype:'1'
    }
  },
  methods:{
    showList(id){
      if(id){
        this.zixuntype = id
      }
      // isShow是否显示下拉隐藏1不显2显
      // label是否显示标签
      this.$router.forward({path:this.url,query:{isShow:1,zixuntype:this.zixuntype}})
    }
  }
}
</script>
